import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { verifyForgetPassSchema } from "../../joi/forgetPassSchema";
import { useValidator } from "react-joi";
// conmponents
import { verifyPassSetData } from "../../../context/api/forget-pass/verify/verifyPassSetData";
import { joiErrorMessage } from "../extra/joiErrorMessage";
import { NavLink } from "react-bootstrap";
const ForgetPassModal = ({ show, onHide, setMessageResponse, token }) => {
  const data = require(`../../../translate/arabic/data.json`);

  const initialMessageState = {
    success: null,
    message: String,
  };

  const [message, setMessage] = useState(initialMessageState);

  const [btnLoader, setBtnLoader] = useState(false);

  const schema = verifyForgetPassSchema().schema;
  const { state, setData, setExplicitField, validate, reset } =
    useValidator(schema);
  const updateOtp = (e) => {
    e.persist();
    setData((old) => ({
      ...old,
      otp: e.target.value,
    }));
  };

  const updateNewPassword = (e) => {
    e.persist();
    setData((old) => ({
      ...old,
      newPassword: e.target.value,
    }));
  };

  const postData = (e) => {
    e.preventDefault();
    const credintial = {
      token: token,
      otp: state.$data.otp,
      newPassword: state.$data.newPassword,
    };
    if (state.$all_source_errors.length < 1) {
      setBtnLoader(() => true);
      return verifyPassSetData(
        credintial,
        setMessage,
        setMessageResponse,
        setBtnLoader
      );
    }
  };

  const SuccessMsgRes = () => {
    return (
      <>
        <Modal.Body
          className="d-flex align-items-center justify-content-center"
          style={{ height: "300px" }}
        >
          <h1>{message.message}</h1>
        </Modal.Body>
        <Modal.Footer className="w-100 d-flex align-items-center justify-content-center">
          <div className="w-100 double-btns d-flex flex-row align-items-center justify-content-center gap-2">
            <Button onClick={onHide} href="/login">
              {data.pages.otp.toLogin}
            </Button>
          </div>
        </Modal.Footer>
      </>
    );
  };

  return (
    <Modal
      show={show.isShow}
      onHide={onHide}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forget-pass-otp-form"
    >
      {message.success ? (
        <SuccessMsgRes />
      ) : (
        <>
          <Modal.Body className="d-flex gap-5 flex-column align-items-center">
            <div className="otp-form-header w-100 my-3 d-flex flex-column align-items-center">
              <h4>{data.pages.otp.title}</h4>
            </div>
            <div
              className="otp-form-inputs d-flex flex-column align-items-center"
              dir={"rtl"}
            >
              <Form.Group className="mb-3">
                <Form.Label>{data.pages.otp.otp}</Form.Label>
                <Form.Control
                  placeholder={data.pages.otp.otp_placeholder}
                  onChange={updateOtp}
                  onBlur={() => setExplicitField("otp", true)}
                />
                <Form.Text style={{ color: "red" }}>
                  {joiErrorMessage(state.$errors.otp)}
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{data.pages.otp.confirm_pass}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={data.pages.otp.confirm_pass_placeholder}
                  onChange={updateNewPassword}
                  onBlur={() => setExplicitField("newPassword", true)}
                />
                <Form.Text style={{ color: "red" }}>
                  {joiErrorMessage(state.$errors.newPassword)}
                </Form.Text>
              </Form.Group>
            </div>
          </Modal.Body>

          <Modal.Footer className="w-100 d-flex flex-row align-items-center">
            <div className="w-100 double-btns d-flex flex-row align-items-center justify-content-center gap-2">
              <Button
                onClick={(e) => {
                  validate();
                  postData(e);
                }}
                disabled={btnLoader ? true : false}
              >
                {data.pages.otp.reset_pass}
              </Button>
              <Button onClick={onHide}>{data.pages.otp.close}</Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ForgetPassModal;
