import React, { useState, useEffect } from "react";
import Joi from "joi";
import { searchGraduateSchema } from "../../../../joi/searchGraduateSchema";
import { useCookies } from "react-cookie";
import { searchGraduateSetData } from "../../../../../context/api/graduate/search-graduate/searchGraduateSetData";
import Message from "../../Message";
// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";



// shared component
import MainFooter from './shared/MainFooter';
import MainHeader from './shared/MainHeader';

const SearchGraduateModal = ({
    show,
    onHide,
    dataTranslate,
    setSearchData,
    search,
    countries,
    iraqiUniversities,
    getLastFiveYears,
    setTableData,
    setRefreshData,
    setActiveSearchInput
}) => {
    // define the Joi Schema 
    const schema = searchGraduateSchema();


    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);

    const [btnLoader, setBtnLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [cookies] = useCookies();
    const [activeSearchBtn, setActiveSearchBtn] = useState(false);


    const searchGraudate = () => {
        var objectSchema = Joi.object().keys(schema);
        const result = objectSchema.validate(search, { abortEarly: false });
        const { error } = result;
        if (!error) {
            // pass the payload to the context API to do the request
            setBtnLoader(() => true);
            return searchGraduateSetData(
                setMessageResponse,
                cookies,
                search,
                setSuccessMessage,
                setBtnLoader,
                setTableData,
                onHide,
                setActiveSearchInput
            );
        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrors(errorData);
            return errorData;
        }
    }

    // const reset = () => {
    //     console.log("IM in ")
    //     setSearchData({});
    //     setErrors({

    //     })
    // }

    const handleOnChange = (e) => {

        const { name, value } = e.target;
        if (value === "") {
            setSearchData((prevItems) => {
                const { [name]: _, ...newData } = prevItems;
                return newData;
            })
            return;
        }

        if (activeSearchBtn === false) {
            setActiveSearchBtn(true);
        }

        let errorData = { ...errors };
        const errorMessage = validateProperty(e);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        let searchData = { ...search };
        searchData[name] = value;
        setSearchData(searchData);
        setErrors(errorData);
    }

    const validateProperty = (event) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = schema[name];
        const result = subSchema.validate(value);
        const { error } = result;
        return error ? error.details[0].message : null;
    }


    const removeKeys = (keysToRemove) => {
        // Updating searchData
        setSearchData(prevState => {
            const newState = Object.keys(prevState)
                .filter(key => !keysToRemove.includes(key))
                .reduce((acc, key) => {
                    acc[key] = prevState[key];
                    return acc;
                }, {});
            return newState;
        });

        // Updating errors
        setErrors(prevState => {
            const newState = Object.keys(prevState)
                .filter(key => !keysToRemove.includes(key))
                .reduce((acc, key) => {
                    acc[key] = prevState[key];
                    return acc;
                }, {});

            return newState;
        });
    };

    useEffect(() => {
        // Perform actions when searchData updates
        if (Object.keys(search).length < 1) {
            setActiveSearchBtn(false);
        }
    }, [search]); // Dependency on searchData

    return (

        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />

            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
                dir={"rtl"}
            >
                <>
                    <MainHeader dataTranslate={dataTranslate} functions={{
                        onHide
                    }} />

                    <Modal.Body>
                        <div dir={"rtl"}>
                            <Card>
                                <Card.Header className="d-flex">
                                    <div>{dataTranslate.popups.search_graduate.code_information_p_search}</div>
                                    <NavLink style={{ marginRight: "auto", color: "#ffffff" }} to="#" onClick={() => {
                                        removeKeys(['userName'])
                                    }}>
                                        مسح
                                    </NavLink>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="mb-lg-3">
                                        <Col lg={4} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.search_graduate.code
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="userName"
                                                    dir="rtl"
                                                    placeholder={dataTranslate.popups.search_graduate.code_p}
                                                    value={search.userName || ''}
                                                    onChange={handleOnChange}
                                                />
                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.userName}
                                                </Form.Text>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Header className="d-flex">
                                    <div>
                                        {dataTranslate.popups.search_graduate.personal_information_p_search}
                                    </div>
                                    <NavLink style={{ marginRight: "auto", color: "#ffffff" }} to="#" onClick={() => {
                                        removeKeys(['firstName',
                                            'secondName',
                                            'thirdName',
                                            'fourthName',
                                            'sureName',
                                            'motherFirstName',
                                            'motherSecondName',
                                            'motherThirdName'])
                                    }}>
                                        مسح
                                    </NavLink>
                                </Card.Header>
                                <Card.Body>
                                    <Container>

                                        <Row className="mb-lg-3">
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.firstName
                                                        }
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="Text"
                                                        name="firstName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.firstName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.firstName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.secondName
                                                        }

                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="secondName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.secondName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.secondName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.thirdName
                                                        }

                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="thirdName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.thirdName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.thirdName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.fourthName
                                                        }

                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="fourthName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.fourthName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.fourthName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row className="mb-lg-3">
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.sureName
                                                        }
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="sureName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.sureName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.sureName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.motherFirstName
                                                        }

                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="Text"
                                                        name="motherFirstName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.motherFirstName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.motherFirstName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.motherSecondName
                                                        }

                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="motherSecondName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.motherSecondName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.motherSecondName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.popups.add_graduate.motherThirdName
                                                        }

                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="motherThirdName"
                                                        placeholder={
                                                            dataTranslate.popups.add_graduate.arabic_language
                                                        }
                                                        onChange={handleOnChange}
                                                        value={search.motherThirdName || ''}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.motherThirdName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                    </Container>

                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Header className="d-flex">
                                    <div>
                                        {dataTranslate.popups.search_graduate.study_information_p_search}
                                    </div>
                                    <NavLink style={{ marginRight: "auto", color: "#ffffff" }} to="#" onClick={() => {
                                        removeKeys(['scholarship',
                                            'country',
                                            'degree',
                                            'universityNameAR',
                                            'universityNameEN',
                                            'studyType',
                                            'collegeName',
                                            'department',
                                            'rate',
                                            'rank',
                                            'graduate_year'])
                                    }}>
                                        مسح
                                    </NavLink>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="mb-lg-3">
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.scholarship
                                                    }
                                                </Form.Label>
                                                <Form.Select
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="scholarship"
                                                    onChange={handleOnChange}
                                                    value={search.scholarship || ''}
                                                >
                                                    <option hidden>
                                                        {dataTranslate.popups.add_graduate.scholarship_type.choose}
                                                    </option>
                                                    <option value={true}>
                                                        {dataTranslate.popups.add_graduate.scholarship_type.true}
                                                    </option>
                                                    <option value={false}>
                                                        {dataTranslate.popups.add_graduate.scholarship_type.false}
                                                    </option>
                                                </Form.Select>

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.scholarship}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.country
                                                    }

                                                </Form.Label>
                                                {(() => {
                                                    switch (search.scholarship) {
                                                        case "true":
                                                            return (
                                                                <Form.Select
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="country"
                                                                    onChange={handleOnChange}
                                                                    defaultValue={search.country || ''}
                                                                >
                                                                    <option hidden>
                                                                        {dataTranslate.popups.add_graduate.country_type.choose}
                                                                    </option>
                                                                    {countries.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.arabicName}>
                                                                                {item.arabicName}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                            )
                                                        case "false":
                                                            return (
                                                                <Form.Select
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="country"
                                                                    disabled
                                                                >
                                                                    <select  disabled>
                                                                        {"العراق"}
                                                                    </select>

                                                                </Form.Select>
                                                            )
                                                        default:
                                                            return (
                                                                <Form.Select
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="country"

                                                                    disabled

                                                                >
                                                                    <option hidden>
                                                                        {dataTranslate.popups.add_graduate.country_type.choose}
                                                                    </option>

                                                                </Form.Select>
                                                            )
                                                    }
                                                })()}
                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.country}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.degree
                                                    }

                                                </Form.Label>
                                                <Form.Select
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="degree"
                                                    disabled={search.country === "" ? true : false}
                                                    onChange={handleOnChange}
                                                    value={search.degree || ''}
                                                >
                                                    <option hidden>
                                                        {dataTranslate.popups.add_graduate.degree_type.choose}
                                                    </option>
                                                    <option value={"pgd"}>
                                                        {dataTranslate.popups.add_graduate.degree_type.pgd}
                                                    </option>
                                                    <option value={"bsc"}>
                                                        {dataTranslate.popups.add_graduate.degree_type.bsc}
                                                    </option>
                                                    <option value={"msc"}>
                                                        {dataTranslate.popups.add_graduate.degree_type.msc}
                                                    </option>
                                                    <option value={"phd"}>
                                                        {dataTranslate.popups.add_graduate.degree_type.phd}
                                                    </option>
                                                </Form.Select>

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.degree}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.universityNameAR
                                                    }

                                                </Form.Label>
                                                {(() => {
                                                    switch (search.scholarship) {
                                                        case "true":
                                                            return (
                                                                <Form.Control
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="universityNameAR"
                                                                    placeholder={
                                                                        dataTranslate.popups.add_graduate.universityNameAR_type.write
                                                                    }
                                                                    disabled={search.degree === "" ? true : false}
                                                                    value={search.universityNameAR || ''}
                                                                    onChange={handleOnChange}
                                                                />
                                                            )
                                                        case "false":
                                                            return (
                                                                <Form.Select
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="universityNameAR"
                                                                    disabled={search.degree === "" ? true : false}
                                                                    value={search.universityNameAR || ''}
                                                                    onChange={handleOnChange}
                                                                >
                                                                    <option hidden>
                                                                        {dataTranslate.popups.add_graduate.universityNameAR_type.choose}
                                                                    </option>
                                                                    {iraqiUniversities.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.name}>
                                                                                {item.name}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                            )
                                                        default:
                                                            return (
                                                                <Form.Select
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="universityNameAR"
                                                                    value={search.universityNameAR || ''}
                                                                    disabled={search.degree === "" ? true : false}
                                                                    onChange={handleOnChange}
                                                                >
                                                                    <option hidden>
                                                                        {dataTranslate.popups.add_graduate.universityNameAR_type.choose}
                                                                    </option>
                                                                    {iraqiUniversities.map((item, index) => {
                                                                        return (
                                                                            <option key={index} value={item.name}>
                                                                                {item.name}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                            )
                                                    }
                                                })()}

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.universityNameAR}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-lg-3">
                                        {(() => {
                                            switch (search.scholarship) {
                                                case "true":
                                                    return (
                                                        <Col lg={true} className="mb-3 mb-lg-0">
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    {
                                                                        dataTranslate.popups.add_graduate.universityNameEN
                                                                    }
                                                                </Form.Label>
                                                                <Form.Control
                                                                    // disabled={!menuLoading ? true : false}
                                                                    type="text"
                                                                    name="universityNameEN"
                                                                    disabled={search.degree === "" ? true : false}
                                                                    placeholder={
                                                                        dataTranslate.popups.add_graduate.universityNameEN_type.write
                                                                    }
                                                                    value={search.universityNameEN || ''}
                                                                    onChange={handleOnChange}

                                                                />
                                                                <Form.Text style={{ color: "red" }}>
                                                                    {errors.universityNameEN}
                                                                </Form.Text>
                                                            </Form.Group>
                                                        </Col>
                                                    )
                                                default:
                                                    return <></>
                                            }
                                        })()}
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.collegeName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="Text"
                                                    name="collegeName"
                                                    placeholder={
                                                        dataTranslate.popups.add_graduate.collageName_p
                                                    }
                                                    disabled={search.degree == "" ? true : false}
                                                    value={search.collegeName || ''}
                                                    onChange={handleOnChange}
                                                />

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.collegeName}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.department
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="Text"
                                                    name="department"
                                                    placeholder={
                                                        dataTranslate.popups.add_graduate.department_p
                                                    }
                                                    disabled={search.degree == "" ? true : false}
                                                    value={search.department || ''}
                                                    onChange={handleOnChange}
                                                />

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.department}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row className="mb-lg-3">
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.studyType
                                                    }

                                                </Form.Label>
                                                <Form.Select
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="studyType"

                                                    disabled={search.degree == "" ? true : false}
                                                    value={search.studyType || ''}
                                                    onChange={handleOnChange}
                                                >
                                                    <option hidden key="1">
                                                        {dataTranslate.popups.add_graduate.studyType_type.choose}
                                                    </option>
                                                    <option value={dataTranslate.popups.add_graduate.studyType_type.morning} key="2">
                                                        {dataTranslate.popups.add_graduate.studyType_type.morning}
                                                    </option>
                                                    <option value={dataTranslate.popups.add_graduate.studyType_type.evening} key="3">
                                                        {dataTranslate.popups.add_graduate.studyType_type.evening}
                                                    </option>

                                                </Form.Select>

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.studyType}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.rate
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="number"
                                                    name="rate"
                                                    placeholder={
                                                        dataTranslate.popups.add_graduate.rate_p
                                                    }
                                                    min={0}
                                                    max={100}
                                                    disabled={search.degree == "" ? true : false}
                                                    value={search.rate || ''}
                                                    onChange={handleOnChange}
                                                />

                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.rate}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.rank
                                                    }

                                                </Form.Label>
                                                <Form.Select
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="rank"
                                                    value={search.rank || ''}
                                                    onChange={handleOnChange}
                                                    disabled={search.degree == "" ? true : false}

                                                >
                                                    <option hidden key="1">
                                                        {dataTranslate.popups.add_graduate.graduate_year_type.choose}
                                                    </option>
                                                    <option value="1st" key="2">
                                                        {"الاول"}
                                                    </option>
                                                    <option value="2nd" key="3">
                                                        {"الثاني"}
                                                    </option>
                                                    <option value="3rd" key="4">
                                                        {"الثالث"}
                                                    </option>
                                                </Form.Select>
                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.rank}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.graduate_year
                                                    }

                                                </Form.Label>
                                                <Form.Select
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="graduate_year"
                                                    value={search.graduate_year || ''}
                                                    onChange={handleOnChange}
                                                    disabled={search.degree == "" ? true : false}

                                                >
                                                    <option hidden>
                                                        {dataTranslate.popups.add_graduate.graduate_year_type.choose}
                                                    </option>
                                                    {getLastFiveYears.map((item, index) => {
                                                        return (
                                                            <option value={item} key={index}>
                                                                {item}
                                                            </option>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <Form.Text style={{ color: "red" }}>
                                                    {errors.graduate_year}
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </div>
                    </Modal.Body>
                    <MainFooter functions={{
                        searchGraudate, onHide, setSearchData, setActiveSearchBtn,
                        setActiveSearchInput, setRefreshData
                    }} status={{ btnLoader, activeSearchBtn }} dataTranslate={dataTranslate} />
                </>

            </Modal >
        </>
    )
}

export default SearchGraduateModal;
