import Joi from "joi";

export const resetPassSchema = (lang) => {
  const joiMessage = require(`../../translate/arabic/joi.json`);

  const schema = {
    initialData: {
      newPassword: null,
    },
    schema: Joi.object({
      newPassword: Joi.string()
        .required()
        .regex(/^(?=.*\d)(?=.*[a-z]).{8,}$/)
        .min(8)
        .max(40)
        .messages({
          "string.empty": joiMessage.common.required,
          "string.base":
            joiMessage.forget_pass.verify_forget_pass.new_pass.invalid,
          "string.pattern.base":
            joiMessage.forget_pass.verify_forget_pass.new_pass.case1,
          "string.min": joiMessage.forget_pass.verify_forget_pass.new_pass.min,
          "string.max": joiMessage.forget_pass.verify_forget_pass.new_pass.max,
        }),
    }),
    explicitCheck: {
      newPassword: false,
    },
    validationOptions: {
      abortEarly: true,
    },
  };
  return { schema: schema };
};
