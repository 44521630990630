import { editGraduateApi } from "./editGraduateApi";

export const editGraduateSetData = async (
    setMessageResponse,
    cookies,
    payload,
    setSuccessMessage,
    setBtnLoader,
    id,
    data
) => {
    const response = await editGraduateApi(payload, cookies, id, data);
    if (response.success) {
        setSuccessMessage((prev) => ({
            ...prev,
            success: true,
            type: "add",
            data: response.res.data.data,
        }));
        setBtnLoader(() => false);
    } else {
        const error = response.res;
        if (error.response) {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: error.response.data.message,
                isShow: true,
            }));
            setBtnLoader(() => false);
        } else {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: "Timeout",
                isShow: true,
            }));
            setBtnLoader(() => false);
        }
    }
};
