import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";


const Cards = ({
    dataCard1,
    switcher_1,
    activeCard,
    setActiveCard,
    fetchTableData,
    pageClass
}) => {
    const dataTranslate = require(`../../translate/arabic/data.json`);

    return (
        <div
            className={`${pageClass}-cards w-100 d-flex flex-row`}
        >
            <div className={`${pageClass}-cards-section1`}>
                <Container className={`d-flex align-items-center justify-content-center`}>
                    <Row className="card-container gap-2">
                        {dataCard1.map((item, index) => {
                            let { key, title } = switcher_1(item, dataTranslate);
                            return (
                                <Col key={index}
                                    onClick={() => {
                                        setActiveCard(key);
                                        fetchTableData(key);
                                    }}
                                >
                                    <Card className={`${pageClass}-cards-section1-card ${activeCard === key ? "active-card" : ""}`} >
                                        <Card.Body
                                            className={"flex-column align-items-center text-center"}
                                        >
                                            <Card.Title style={{ fontSize: '16px' }}>{title}</Card.Title>
                                            <h1 dir="ltr" style={{ fontSize: "16px" }}> {item[key]}</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </div>
        </div>
    )
}


export default Cards;