import React, { useState } from "react";
import Message from "../Message";
import SuccessRes from "./SuccessRes";
import {
    addGraduatePersonalInfoSchema,
    addGraduateStudyInfoSchema
} from "../../../joi/addGraduateSchema";
// import { updateCustomerSchema } from "../../../joi/customerSchema";
import { addGraduateSetData } from "../../../../context/api/graduate/add-graduate/addGraduateSetData";
// import { updateCustomerSetData } from "../../../../context/api/cutomer/update-customer/updateCustomerSetData";
import { useCookies } from "react-cookie";
import Joi from 'joi';
// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";

// shared component
import MainFooter from './shared/MainFooter';
import MainHeader from './shared/MainHeader';

const CreateGraduateModal = ({
    show,
    onHide,
    dataTranslate,
    action,
    data,
    setRefreshData,
    menuLoading,
    countries,
    iraqiUniversities,
    getLastFiveYears
}) => {

    // const dataTranslate = require("../../../../translate/arabic/data.json");
    const personalInfoSchmea = addGraduatePersonalInfoSchema();
    const studyInfoSchmea = addGraduateStudyInfoSchema();

    const initPeronsalInfo = {
        firstName: "",
        secondName: "",
        thirdName: "",
        fourthName: "",
        sureName: "",
        motherFirstName: "",
        motherSecondName: "",
        motherThirdName: ""
    };

    const [personalInfo, setPeronsalInfo] = useState(initPeronsalInfo);

    const initStudyInfo = {
        scholarship: null,
        countryName: "",
        id: "",
        universityNameAR: "",
        universityNameEN: "",
        studyType: "",
        collegeName: "",
        departmentName: "",
        graduationRate: "",
        graduationRank: "",
        graduate_year: ""
    };
    const [studyInfo, setStudyInfo] = useState(initStudyInfo);

    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };

    const [errors, setErrors] = useState({});
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
    const [cookies] = useCookies();
    const [btnLoader, setBtnLoader] = useState(false);
    const [EXRLoading, setEXRLoading] = useState(false);
    const [EXRData, setEXRData] = useState([]);
    const initDegreeSelectValue = "";
    const [degreeSelectedValue, setDegreeSelectedValue] = useState(initDegreeSelectValue);

    const createGraduate = () => {
        var objectSchema = Joi.object().keys({ ...personalInfoSchmea, ...studyInfoSchmea });
        const graduateData = { ...personalInfo, ...studyInfo };
        const result = objectSchema.validate(graduateData, { abortEarly: false });
        const { error } = result;
        if (!error) {
            // pass the payload to the context API to do the request
            setBtnLoader(() => true);
            return addGraduateSetData(
                setMessageResponse,
                cookies,
                graduateData,
                setSuccessMessage,
                setBtnLoader,
            );
        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            console.log(errorData)
            setErrors(errorData);
            return errorData;
        }
    }

    const reset = () => {
        resetPeronsalInfo();
        resetStudyInfo("all");
        setErrors({

        })
    }

    const resetPeronsalInfo = () => {
        setPeronsalInfo(initPeronsalInfo);
    }
    const resetStudyInfo = (mode) => {
        setStudyInfo({ ...initStudyInfo, scholarship: (mode === "all" ? null : studyInfo.scholarship) });
    }


    const personalInfoOnChange = (e) => {
        const { name, value } = e.target;
        let personalInfoErrorsData = { ...errors };
        const errorMessage = validateProperty(e, personalInfoSchmea);
        if (errorMessage) {
            personalInfoErrorsData[name] = errorMessage;
        } else {
            delete personalInfoErrorsData[name];
        }
        let personalInfoData = { ...personalInfo };
        personalInfoData[name] = value;
        setPeronsalInfo(personalInfoData)
        setErrors(personalInfoErrorsData)
    }

    const studyInfoOnChange = (e) => {
        const { name, value } = e.target;
        let studyInfoErrorsData = { ...errors };
        const errorMessage = validateProperty(e, studyInfoSchmea);
        if (errorMessage) {
            studyInfoErrorsData[name] = errorMessage;
        } else {
            delete studyInfoErrorsData[name];
        }
        let studyInfoData = { ...studyInfo };
        studyInfoData[name] = value;
        // switch case for User Experince 
        switch (name) {
            case "scholarship": {
                if (name === "scholarship" && value === "false") {
                    if (studyInfoData["scholarship"] != "") {
                        studyInfoData["id"] = "";
                        studyInfoData = initStudyInfo;
                    }
                    studyInfoData["countryName"] = "العراق"
                } else if (name === "scholarship" && value === "true") {
                    if (studyInfoData["scholarship"] != "") {
                        studyInfoData["id"] = "";
                        studyInfoData = initStudyInfo;
                    }
                    studyInfoData["countryName"] = ""
                }


            }
        }
        studyInfoData[name] = value;
        setStudyInfo(studyInfoData);
        setErrors(studyInfoErrorsData);
    }

    const validateProperty = (event, schema) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = schema[name];
        const result = subSchema.validate(value);
        const { error } = result;
        return error ? error.details[0].message : null;
    };

    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
                dir={"rtl"}
            >
                {successMessage.success ? (
                    <SuccessRes
                        dataTranslate={dataTranslate}
                        onHide={async () => {
                            await onHide();
                            setSuccessMessage(initialSuccessMessage);
                            setRefreshData((prev) => prev + 1);
                        }}
                        res={successMessage}
                    />
                ) : (
                    <>
                        <MainHeader dataTranslate={dataTranslate} data={data} action={action} functions={{
                            reset, onHide, setEXRLoading,
                            setEXRData
                        }} />
                        <Modal.Body>
                            <div dir={"rtl"}>
                                <Card>
                                    <Card.Header>
                                        {dataTranslate.personal_information_p}
                                    </Card.Header>
                                    <Card.Body>
                                        <Container>

                                            <Row className="mb-lg-3">
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.firstName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="Text"
                                                            name="firstName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.firstName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.firstName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.secondName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="secondName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.secondName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.secondName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.thirdName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="thirdName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.thirdName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.thirdName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.fourthName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="fourthName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.fourthName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.fourthName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-lg-3">
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.sureName
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="sureName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.sureName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.sureName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.motherFirstName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="Text"
                                                            name="motherFirstName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.motherFirstName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.motherFirstName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.motherSecondName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="motherSecondName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.motherSecondName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.motherSecondName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.motherThirdName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="motherThirdName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            value={personalInfo.motherThirdName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.motherThirdName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </Container>

                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        {dataTranslate.study_infromation_p}
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="mb-lg-3">
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.scholarship
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="scholarship"
                                                        value={studyInfo.scholarship}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden>
                                                            {dataTranslate.scholarship_type.choose}
                                                        </option>
                                                        <option value={true}>
                                                            {dataTranslate.scholarship_type.true}
                                                        </option>
                                                        <option value={false}>
                                                            {dataTranslate.scholarship_type.false}
                                                        </option>
                                                    </Form.Select>

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.scholarship}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.country
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    {(() => {
                                                        switch (studyInfo.scholarship) {
                                                            case "true":
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="countryName"
                                                                        value={studyInfo.countryName}
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.country_type.choose}
                                                                        </option>
                                                                        {countries.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.arabicName}>
                                                                                    {item.arabicName}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )
                                                            case "false":
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="countryName"
                                                                        value={studyInfo.countryName}
                                                                        disabled
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option selected disabled>
                                                                            {"العراق"}
                                                                        </option>

                                                                    </Form.Select>
                                                                )
                                                            default:
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="countryName"
                                                                        value={studyInfo.countryName}
                                                                        disabled
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.country_type.choose}
                                                                        </option>

                                                                    </Form.Select>
                                                                )
                                                        }
                                                    })()}
                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.countryName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.degree
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="id"
                                                        value={studyInfo.id}
                                                        disabled={studyInfo.countryName === "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden>
                                                            {dataTranslate.degree_type.choose}
                                                        </option>
                                                        <option value={1}>
                                                            {dataTranslate.degree_type.pgd}
                                                        </option>
                                                        <option value={2}>
                                                            {dataTranslate.degree_type.bsc}
                                                        </option>
                                                        <option value={3}>
                                                            {dataTranslate.degree_type.msc}
                                                        </option>
                                                        <option value={4}>
                                                            {dataTranslate.degree_type.phd}
                                                        </option>
                                                    </Form.Select>

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.id}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.universityNameAR
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    {(() => {
                                                        switch (studyInfo.scholarship) {
                                                            case "true":
                                                                return (
                                                                    <Form.Control
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameAR"
                                                                        placeholder={
                                                                            dataTranslate.universityNameAR_type.write
                                                                        }
                                                                        disabled={studyInfo.id === "" ? true : false}
                                                                        onChange={studyInfoOnChange}
                                                                        value={studyInfo.universityNameAR}
                                                                    />
                                                                )
                                                            case "false":
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameAR"
                                                                        value={studyInfo.universityNameAR}
                                                                        disabled={studyInfo.id === "" ? true : false}
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.universityNameAR_type.choose}
                                                                        </option>
                                                                        {iraqiUniversities.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.universityNameAR}>
                                                                                    {item.universityNameAR}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )
                                                            default:
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameAR"
                                                                        defaultValue=""
                                                                        disabled={studyInfo.id === "" ? true : false}
                                                                        onChange={studyInfoOnChange}
                                                                        value={studyInfo.universityNameAR}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.universityNameAR_type.choose}
                                                                        </option>
                                                                        {iraqiUniversities.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.name}>
                                                                                    {item.name}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )
                                                        }
                                                    })()}

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.universityNameAR}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-lg-3">
                                            {(() => {
                                                switch (studyInfo.scholarship) {
                                                    case "true":
                                                        return (
                                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        {
                                                                            dataTranslate.universityNameEN
                                                                        }
                                                                        {action === "add" ? (
                                                                            <span>
                                                                                <sup style={{ color: "red" }}> *</sup>
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameEN"
                                                                        disabled={studyInfo.id === "" ? true : false}
                                                                        placeholder={
                                                                            dataTranslate.universityNameEN_type.write
                                                                        }
                                                                        onChange={studyInfoOnChange}
                                                                        value={studyInfo.universityNameEN}
                                                                    />
                                                                    <Form.Text style={{ color: "red" }}>
                                                                        {errors.universityNameEN}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                    default:
                                                        return <></>
                                                }
                                            })()}
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.collegeName
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="Text"
                                                        name="collegeName"
                                                        placeholder={
                                                            dataTranslate.collageName_p
                                                        }
                                                        disabled={studyInfo.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                        value={studyInfo.collegeName}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.collegeName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.department
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="Text"
                                                        name="departmentName"
                                                        placeholder={
                                                            dataTranslate.department_p
                                                        }
                                                        disabled={studyInfo.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                        value={studyInfo.departmentName}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.departmentName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row className="mb-lg-3">
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.studyType
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="studyType"
                                                        value={studyInfo.studyType}
                                                        disabled={studyInfo.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden key="1">
                                                            {dataTranslate.studyType_type.choose}
                                                        </option>
                                                        <option value={dataTranslate.studyType_type.morning} key="2">
                                                            {dataTranslate.studyType_type.morning}
                                                        </option>
                                                        <option value={dataTranslate.studyType_type.evening} key="3">
                                                            {dataTranslate.studyType_type.evening}
                                                        </option>

                                                    </Form.Select>

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.studyType}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.rate
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="number"
                                                        name="graduationRate"
                                                        placeholder={
                                                            dataTranslate.rate_p
                                                        }
                                                        min={50}
                                                        max={100}
                                                        disabled={studyInfo.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                        value={studyInfo.graduationRate}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.graduationRate}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.rank
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="graduationRank"
                                                        value={studyInfo.graduationRank}
                                                        disabled={studyInfo.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden key="1">
                                                            {dataTranslate.graduate_year_type.choose}
                                                        </option>
                                                        <option value="1" key="2">
                                                            {"الاول"}
                                                        </option>
                                                        <option value="2" key="3">
                                                            {"الثاني"}
                                                        </option>
                                                        <option value="3" key="4">
                                                            {"الثالث"}
                                                        </option>
                                                    </Form.Select>
                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.graduationRank}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.graduate_year
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="graduate_year"
                                                        value={studyInfo.graduate_year}
                                                        disabled={studyInfo.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden>
                                                            {dataTranslate.graduate_year_type.choose}
                                                        </option>
                                                        {getLastFiveYears.map((item, index) => {
                                                            return (
                                                                <option value={item} key={index}>
                                                                    {item}
                                                                </option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.graduate_year}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </div>
                        </Modal.Body>
                        <MainFooter functions={{
                            createGraduate, reset, onHide, setEXRLoading,
                            setEXRData
                        }} status={{ menuLoading, btnLoader }} dataTranslate={dataTranslate} />
                    </>
                )}
            </Modal>
        </>
    )
}

export default CreateGraduateModal;