import React, { Suspense } from "react";
import Store from './context/Store';
import { Spinner } from "react-bootstrap";
import "./assets/sass/app.scss";
import Main from "./components/Main";

// import ThemeProvider from "react-bootstrap/ThemeProvider";
function App() {
  return (
    <Suspense
      fallback={
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">...</span>
          </Spinner>
        </div>
      }>

      <Store>
        <Main />
      </Store>
    </Suspense>
  );
}

export default App;
