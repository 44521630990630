import axios from "axios";

export const loginApi = async (credintial, lang) => {
    let data = JSON.stringify({
        email: credintial.email,
        password: credintial.password,
    });
    let headers = {
        'Content-Type': 'application/json'
    }

    let config = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_API}/auth/login`,
        headers: headers,
        data: data,
        timeout: 15000,
    };

    try {
        const res = await axios(config);
        return { success: res.data.success, res: res };
    } catch (error) {
        return { success: false, res: error };
    }
};
