import axios from "axios";

export const editGraduateApi = async (credintial, cookies, id, data) => {
    const token = cookies._token;
    if(credintial.hasOwnProperty("id")){
        credintial["id"] = parseInt(credintial["id"])
    }
    if(credintial.hasOwnProperty("scholarship")){
        credintial["scholarship"] = Boolean(credintial["scholarship"])
    }
    let payload = {...data, ...credintial}
    delete payload.password;
    delete payload.userName;
    let config = {
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_API}/update-graduate/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(payload),
        timeout: 15000,
    };

    try {
        const res = await axios(config);
        return { success: res.data.success, res: res };
    } catch (error) {
        return { success: false, res: error };
    }
};
