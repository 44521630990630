import React, { useState, useEffect } from "react";

import Message from "../../Message";
import SuccessRes from "./SuccessRes";
import { editUserSchema } from "../../../../joi/editUserSchema";
// import { editShipmentSetData } from "../../../../../context/api/shipment/edit-shipment/editShipmentSetData";
import { useCookies } from "react-cookie";
import Joi from 'joi';
import { editUserSetData } from "../../../../../context/api/office/edit-user/editUserSetData";
// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";


// shared component
import MainFooter from '../shared/MainFooter';
import MainHeader from '../shared/MainHeader';

const EditUserModal = ({
    show,
    onHide,
    action,
    data,
    setRefreshData,
    dataTranslate,
}) => {
    const schema = editUserSchema();
    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };

    const [errors, setErrors] = useState({});
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
    const [cookies] = useCookies();
    const [btnLoader, setBtnLoader] = useState(false);
    const [activeEditBtn, setActiveEditBtn] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    const editUser = () => {
        const objectSchema = Joi.object().keys(schema);
        const result = objectSchema.validate(userInfo, { abortEarly: false });
        const { error } = result;
        if (!error) {
            // pass the payload to the context API to do the request
            setBtnLoader(() => true);
            return editUserSetData(
                setMessageResponse,
                cookies,
                userInfo,
                setSuccessMessage,
                setBtnLoader,
                data.id,
                data
            );
        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrors(errorData);
            return errorData;
        }
    }
    const handleOnChange = (e) => {
        if (activeEditBtn === false) {
            setActiveEditBtn(true);
        }
        const { name, value } = e.target;
        let errorData = { ...errors };
        const errorMessage = validateProperty(e);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        let userData = { ...userInfo };
        userData[name] = value;
        setUserInfo(userData);
        setErrors(errorData);
    }

    const validateProperty = (event) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = schema[name];
        const result = subSchema.validate(value);
        const { error } = result;
        return error ? error.details[0].message : null;
    };


    const reset = () => {
        setUserInfo({

        })
        setErrors({
        })
    }

    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />

            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
                dir={"rtl"}
            >
                {successMessage.success ? (
                    <SuccessRes
                        dataTranslate={dataTranslate}
                        onHide={async () => {
                            await onHide();
                            setSuccessMessage(initialSuccessMessage);
                            setRefreshData((prev) => prev + 1);
                        }}
                        res={successMessage}
                    />
                ) : (
                    <>
                        <MainHeader dataTranslate={dataTranslate} data={data} action={action} functions={{
                            reset, onHide, setActiveEditBtn
                        }} />

                        <Modal.Body>
                            <div dir={"rtl"}>
                                <Card>
                                    <Card.Header>
                                        {dataTranslate.userInfo}
                                    </Card.Header>
                                    <Card.Body>
                                        <Container>
                                            <Row className="mb-lg-3">
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.email
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="email"
                                                            name="email"
                                                            placeholder={
                                                                dataTranslate.email_p
                                                            }
                                                            onChange={handleOnChange}
                                                            defaultValue={data.email}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.email}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.password
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="password"
                                                            placeholder={
                                                                dataTranslate.password_p
                                                            }
                                                            onChange={handleOnChange}
                                                            defaultValue={data.password}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.password}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.affairs
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="name"
                                                            placeholder={
                                                                dataTranslate.affairs_p
                                                            }
                                                            onChange={handleOnChange}
                                                            defaultValue={data.name}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.name}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.role
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Select
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="role_name"
                                                            defaultValue={data.role_name}
                                                            onChange={handleOnChange}
                                                        >
                                                            <option hidden>
                                                                {dataTranslate.choose}
                                                            </option>
                                                            {dataTranslate.role_list.map((item, index) => {
                                                                return <option value={item.value} key={index}>
                                                                    {item.title}
                                                                </option>
                                                            })}
                                                        </Form.Select>

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.role_name}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Card.Body>
                                </Card>


                            </div>
                        </Modal.Body>

                        <MainFooter functions={{
                            editUser, reset, onHide, setActiveEditBtn
                        }} status={{ btnLoader, activeEditBtn }} dataTranslate={dataTranslate} action={action} />
                    </>
                )}
            </Modal>
        </>
    );
}


export default EditUserModal;