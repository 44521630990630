import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    useReactTable,
    PaginationState,
    getPaginationRowModel
} from "@tanstack/react-table";
import { useState, useMemo, useEffect } from "react";
import Pagination from "./Pagination";
import { tab } from "@testing-library/user-event/dist/tab";
import { Spinner } from "react-bootstrap";
import { hover } from "@testing-library/user-event/dist/hover";
const DataTable = ({
    columns,
    data,
    loader,
    searchValue,
    field,
    columnFilters,
    setColumnFilters
}) => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });


    const table = useReactTable({
        data,
        columns,
        // debugTable: true,
        enableFilters: true,
        enableColumnFilters: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            columnFilters,
            pagination,
        },
        onColumnFiltersChange: setColumnFilters,
    });

    const filteringColumn = useMemo(() => {
        return table.getAllColumns().filter((col) => col.id === field)[0];
    }, [field, table]);

    useEffect(() => {
        if (filteringColumn) {
            filteringColumn.setFilterValue(searchValue);
        }
    }, [filteringColumn, searchValue]);

    if (loader) {
        return (
            <div className="page_loader">
                <Spinner animation="border" style={{ color: "#1F3D7D" }} />
            </div>
        );
    }

    return (
        <>
            <div className="table-responsive" id="no-more-tables">
                <table className={`table `}  >
                    <thead className={"home_p-table-table-head"}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className={`home_p-table-table-body`}>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id} >
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} data-title={cell.column.columnDef.header}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {table.getRowModel().rows.length === 0 && <tr> <td colSpan={columns.length} style={{ textAlign: "center", fontSize: "32px" }}>لاتوجد بيانات</td></tr>}
                    </tbody>
                </table>
            </div>
            <Pagination
                gotoPage={table.firstPage}
                previousPage={table.previousPage}
                canPreviousPage={table.getCanPreviousPage}
                pageIndex={table.getState().pagination.pageIndex}
                pageLength={table.getPageCount().toLocaleString()}
                nextPage={table.nextPage}
                canNextPage={table.getCanNextPage}
                pageCount={table.getPageCount}
                pageSize={table.getState().pagination.pageSize}
                setPageSize={table.setPageSize}
                lastPage={table.lastPage}
            />
        </>
    );
}


export default DataTable;