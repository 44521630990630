import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

// components
import { signout } from "../utils/extra/signout";
import ResetPassModal from "../utils/modals/settings/ResetPassModal";

const Header = ({ auth, setAuth, removeCookies }) => {
    console.log(auth);
    const navigate = useNavigate();
    const dataTranslate = require("../../translate/arabic/data.json");
    const initialData = {
        method: null,
        isShow: false,
    };
    const initialResetData = {
        isShow: false,
    };
    const username = localStorage.getItem("_affairs");
    const [activeNav, setActiveNav] = useState(`${window.location.pathname}`);
    const [resetModalHandler, setResetModalHandler] = useState(initialResetData);

    const setResetPass = () => {
        setResetModalHandler((prev) => ({
            ...prev,
            isShow: true,
        }));
    };

    if (!auth.isAuthenticated) {
        return <div></div>;
    }

    const handleActiveNav = (key) => {
        setActiveNav(() => key);
    };

    return (
        <>

            <ResetPassModal
                show={resetModalHandler.isShow}
                onHide={() => setResetModalHandler(initialResetData)}
            />


            <div
                className="header d-flex align-items-center"
                dir="rtl"
                style={{ textAlign: "right" }}
            >
                <Navbar className="bg-color-header py-1" expand="lg">
                    <Container fluid>
                        <Nav className="  header-navbar">
                            <Dropdown >
                                <Dropdown.Toggle id="dropdown-basic2">
                                    <FontAwesomeIcon
                                        icon={faGear}
                                        size="xl"
                                        style={{ color: "#1F3D7D" }}
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                    className="header-navbar-dropdown"
                                    size="lg"
                                    style={{
                                        right: "0",
                                        textAlign: "center",
                                        position: "absolute"
                                    }}
                                >

                                    <Dropdown.Item
                                        className="header-navbar-dropdown-items"
                                        onClick={setResetPass}
                                    >
                                        {dataTranslate.pages.navbar.settings.reset_password}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />

                                    <Dropdown.Item
                                        className="header-navbar-dropdown-items"
                                        onClick={() => {
                                            signout(setAuth, removeCookies)
                                            navigate("/login");
                                        }
                                        }
                                    >
                                        {dataTranslate.pages.navbar.settings.signout}
                                    </Dropdown.Item>



                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                        <Nav className=" d-flex flex-row align-items-center gap-2">
                            <div>
                                <FontAwesomeIcon
                                    icon={faUserCircle}
                                    size="xl"
                                    style={{ color: "#1F3D7D" }}
                                />
                            </div>
                            <span className="username">{username}</span>
                        </Nav>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse>
                            <Nav
                                className={"me-auto  gap-3"}
                            >
                                <Link className={

                                    activeNav === "/office" ?
                                        "header-link header-link-active" : "header-link"
                                }
                                    to="/office"
                                    onClick={() => handleActiveNav("/office")}
                                    style={{ textDecoration: 'none' }}
                                >
                                    {dataTranslate.pages.navbar.links.users_management}
                                </Link>

                                <Link className={
                                    activeNav === "/" ?
                                        "header-link header-link-active" : "header-link"
                                }
                                    to="/"
                                    onClick={() => handleActiveNav("/")}
                                    style={{ textDecoration: 'none' }}
                                >
                                    {dataTranslate.pages.navbar.links.home}
                                </Link>

                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}

export default Header;