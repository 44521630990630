import { useState, useEffect } from 'react';
import { ContextApi } from './ContextApi';
import { useCookies } from 'react-cookie';
import { verifyApi } from "./api/verify/verifyApi";
import { signout } from "../components/utils/extra/signout";

const Store = ({ children }) => {
    const [cookies, setCookie, removeCookies] = useCookies();
    const [auth, setAuth] = useState({
        isAuthenticated: null
    });
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const token = cookies._token;
        const verify = async () => {
            await verifyApi(setAuth, token, removeCookies);
        }

        if (token) {
            verify();
        } else {
            signout(setAuth, removeCookies);
        }
    }, [])

    // return function 
    return (
        <ContextApi.Provider
            value={{
                authentication: [auth, setAuth],
                loading: [loader, setLoader]
            }}>
            {children}
        </ContextApi.Provider>
    )
}

export default Store;
