import React from "react";
import SuccessRes from "../../components/utils/modals/graduate-modal/delete-graduate/SuccessRes";
// Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ConfirmModal = ({
    show,
    onHide,
    post,
    data,
    dataTranslate,
    setRefreshData,
    btnLoader,
    action,
    successMessage,
    setSuccessMessage,
}) => {
    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
                dir="rtl"
            >
                {successMessage.success ? (
                    <SuccessRes
                        dataTranslate={dataTranslate}
                        onHide={async () => {
                            await onHide();
                            setSuccessMessage(initialSuccessMessage);
                            setRefreshData((prev) => prev + 1);
                        }}
                        res={successMessage}
                    />
                ) : (
                    <>
                        <Modal.Header className="d-flex align-items-center justify-content-center ">
                            <Modal.Title
                                id="contained-modal-title-vcenter"
                                className="fs-2 d-flex flex-row gap-2"
                            >
                                <span>{action.title}</span>
                                <span style={{ color: "red" }}>{data.fullName}</span>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body className="d-flex aligin-items-center justify-content-center m-5">
                            <div dir="rtl">
                                <h1> {dataTranslate.addotional.confirm}</h1>
                            </div>
                        </Modal.Body>
                        <Modal.Footer
                            className="w-100 d-flex flex-row align-items-center"
                            dir="ltr"
                        >
                            <div className="w-100 resverse-double-btns d-flex flex-row align-items-center justify-content-center gap-2">
                                <Button
                                    onClick={() => {
                                        post(data._id);
                                    }}
                                    disabled={btnLoader ? true : false}
                                >
                                    {action.submit}
                                </Button>

                                <Button
                                    onClick={() => {
                                        onHide();
                                    }}
                                >
                                    {dataTranslate.popups.home_p.close}
                                </Button>
                            </div>
                        </Modal.Footer>
                    </>
                )}
            </Modal>
        </>
    );
};

export default ConfirmModal;
