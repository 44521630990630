import { forgetPassApi } from "./forgetPassApi";

export const forgetPassSetData = async (
  credintial,
  setMessageResponse,
  setBtnLoader,
  setVerifyModal
) => {
  const response = await forgetPassApi(credintial);

  if (response.success) {
    setVerifyModal((prev) => ({
      ...prev,
      token: response.res.data.data.token,
      otp: response.res.data.data.otp,
      isShow: true,
    }));
    setBtnLoader(() => false);
  } else {
    const error = response.res;
    if (error.response) {
      setMessageResponse((prev) => ({
        ...prev,
        success: false,
        message: error.response.data.message,
        isShow: true,
      }));
      setBtnLoader(() => false);
    } else {
      setMessageResponse((prev) => ({
        ...prev,
        success: false,
        message: "Timeout",
        isShow: true,
      }));
      setBtnLoader(() => false);
    }
  }
};
