import Joi from "joi";

export const addGraduatePersonalInfoSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);
    const schema = {
        firstName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        })
            .required().min(2).max(40).messages({
                "string.empty": joiMessage.common.required,
                "string.base": joiMessage.add_graduate_modal.name.base,
                "string.min": joiMessage.add_graduate_modal.name.min,
                "string.max": joiMessage.add_graduate_modal.name.max,
            }),
        secondName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        thirdName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        fourthName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        sureName: Joi.string().allow("").custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        motherFirstName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        motherSecondName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        motherThirdName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        })
    };

    return schema;
}

export const addGraduateStudyInfoSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);

    const schema = {
        scholarship: Joi.string().valid("true", "false").required().messages({
            "boolean.base": joiMessage.common.required,
            "string.base": joiMessage.common.required,
            "any.only": joiMessage.add_graduate_modal.scholarship.valid
        }),
        countryName: Joi.string().min(2).max(100).required().messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.country.base,
            "string.min": joiMessage.add_graduate_modal.country.min,
            "string.max": joiMessage.add_graduate_modal.country.max,
        }),
        id: Joi.string().required().valid("1", "2", "3", "4").messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.degree.base,
            "any.only": joiMessage.add_graduate_modal.degree.valid
        }),
        universityNameAR: Joi.string().custom((value, helpers) => {
            const parent = helpers.state.ancestors[0];
            if (parent && parent.scholarship === "true") {
                // Check if the string contains only Arabic letters and spaces
                if (!/^[ء-ي\s]+$/.test(value)) {
                    return helpers.message(joiMessage.common.arabic_only);
                }
                // Check if the string ends with a space
                if (/\s$/.test(value)) {
                    return helpers.message(joiMessage.common.no_space_arabic);
                }

                if (value.length < 2) {
                    return helpers.error("string.min", { value });
                }

                if (value.length > 100) {
                    return helpers.error("string.max", { value });
                }
                return value; // Return the valid value
            }

        }).required().messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.universityNameAR.base,
            "string.min": joiMessage.add_graduate_modal.universityNameAR.min,
            "string.max": joiMessage.add_graduate_modal.universityNameAR.max,
        }),
        universityNameEN: Joi
            .custom((value, helpers) => {
                const parent = helpers.state.ancestors[0];
                if (parent && parent.scholarship === "true") {
                    if (!value || value.trim() === '') {
                        return helpers.error("string.empty", { value });
                    }
                    if (value.length < 2) {
                        return helpers.error("string.min", { value });
                    }
                    if (!/^[A-Za-z\s]+$/.test(value)) {
                        return helpers.message(joiMessage.common.english_only);
                    }
                    // Check if the string ends with a space
                    if (/\s$/.test(value)) {
                        return helpers.message(joiMessage.common.no_space_arabic);
                    }

                }
                return value;
            })
            .messages({
                "string.empty": joiMessage.common.required,
                "string.base": joiMessage.add_graduate_modal.universityNameEN.base,
                "string.min": joiMessage.add_graduate_modal.universityNameEN.min,
                "string.max": joiMessage.add_graduate_modal.universityNameEN.max,
            }),
        studyType: Joi.string().required().valid("صباحي", "مسائي").messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.studyType.base,
            "any.only": joiMessage.add_graduate_modal.studyType.valid
        }),
        collegeName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(100).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.collegeName.base,
            "string.min": joiMessage.add_graduate_modal.collegeName.min,
            "string.max": joiMessage.add_graduate_modal.collegeName.max,
        }),
        departmentName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).required().min(2).max(100).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.department.base,
            "string.min": joiMessage.add_graduate_modal.department.min,
            "string.max": joiMessage.add_graduate_modal.department.max,
        }),
        graduationRate: Joi.number().positive().min(50).max(100).precision(5).required().messages({
            "string.empty": joiMessage.common.required,
            "number.base": joiMessage.common.required,
            "number.precision": joiMessage.add_graduate_modal.rate.precision,
            "number.positive": joiMessage.add_graduate_modal.rate.number,
            "number.min": joiMessage.add_graduate_modal.rate.min,
            "number.max": joiMessage.add_graduate_modal.rate.max,
        }),
        graduationRank: Joi.string().valid("1", "2", "3").required().messages({
            "string.empty": joiMessage.common.required,
            "any.only": joiMessage.add_graduate_modal.rank.valid
        }),
        graduate_year: Joi.string()
            .pattern(/^[0-9]{4}$/)
            .required()
            .messages({
                "string.empty": joiMessage.common.required,
                "string.pattern.base": joiMessage.add_graduate_modal.graduate_year.pattern,
            })
    }
    return schema;
}