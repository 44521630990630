
export const signout = (setAuth, removeCookies) => {
    localStorage.clear();
    removeCookies("_token");
    const intialAuthState = {
        isAuthenticated: false,
        role: "",
        Permissions: ""
    };
    setAuth(() => intialAuthState);
};
