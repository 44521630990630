import { searchGraduateApi } from "./searchGraduateApi";

export const searchGraduateSetData = async (
    setMessageResponse,
    cookies,
    payload,
    setSuccessMessage,
    setBtnLoader,
    setTableData,
    onHide,
    setActiveSearchInput
) => {
    const response = await searchGraduateApi(payload, cookies);
    if (response.success) {
        setTableData(() => response.res.data.data);
        setActiveSearchInput(true);
        onHide();
        
        // setSuccessMessage((prev) => ({
        //     ...prev,
        //     success: true,
        //     type: "add",
        //     data: response.res.data.data,
        // }));
        setBtnLoader(() => false);
    } else {
        const error = response.res;
        if (error.response) {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: error.response.data.message,
                isShow: true,
            }));
            setBtnLoader(() => false);
        } else {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: "Timeout",
                isShow: true,
            }));
            setBtnLoader(() => false);
        }
    }
};
