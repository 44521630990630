import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SuccessRes = ({  res, onHide, dataTranslate }) => {
    const data = dataTranslate.res_message.update;
    return (
        <>
            <Modal.Header className="d-flex align-items-center justify-content-center ">
                {/* <span className="h1">{res.message}</span> */}
            </Modal.Header>

            <Modal.Body className="m-5">
                <div
                    className="d-flex flex-column align-items-center gap-3 "
                    dir={"rtl"}
                >

                    <div className="d-flex flex-column d-flex flex-column align-items-center gap-1">
                        <h1>{data.title}</h1>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                className="w-100 d-flex flex-row align-items-center"
                dir="ltr"
            >
                <div className="w-100 double-btns d-flex flex-row align-items-center justify-content-center gap-2">
                    <Button onClick={onHide} style={{ backgroundColor: "#e65857" }}>
                        {dataTranslate.close}
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export default SuccessRes;
