import React, { useState } from "react";
import Message from "../Message";
import { useValidator } from "react-joi";
import { useCookies } from "react-cookie";
import { resetPassSchema } from "../../../joi/resetPassSchema";
import { resetPassSetData } from "../../../../context/api/reset-pass/resetPassSetData";
import { joiErrorMessage } from "../../extra/joiErrorMessage";
// Bootstrap
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const ResetPassModal = ({ show, onHide }) => {
    const dataTransalte = require(`../../../../translate/arabic/data.json`);
    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [btnLoader, setBtnLoader] = useState(false);
    const [cookies, setCookie, removeCookies] = useCookies();
    const schema = resetPassSchema().schema;
    const { state, setData, setExplicitField, validate } = useValidator(schema);

    const updateNewPass = (e) => {
        setData((old) => ({
            ...old,
            newPassword: e.target.value,
        }));
    };
    const postData = async (e) => {
        e.preventDefault();
        const credintial = {
            newPassword: state.$data.newPassword,
        };
        if (state.$all_source_errors.length < 1) {
            setBtnLoader(() => true);
            removeCookies("_token");
            return resetPassSetData(
                cookies,
                setCookie,
                credintial,
                setMessageResponse,
                setBtnLoader,
                onHide
            );
        }
    };

    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />

            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
            >
                <Modal.Header>
                    <Modal.Title className="d-flex flex-column align-items-center w-100 fs-2">
                        {dataTransalte.popups.settings.reset_pass}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="m-2 d-flex flex-column align-items-center gap-5"
                    dir={"rtl"}
                >
                    <h4>{dataTransalte.popups.settings.reset_pass_desc}</h4>
                    <Form.Group
                        className="d-flex flex-column align-items-center w-50"
                        controlId="formBasicEmail"
                    >
                        <Form.Label>{dataTransalte.popups.settings.new_pass}</Form.Label>
                        <Form.Control
                            type="password"
                            onChange={updateNewPass}
                            onBlur={() => setExplicitField("newPassword", true)}
                            autocomplete="new-password"
                        />
                        <Form.Text style={{ color: "red" }}>
                            {joiErrorMessage(state.$errors.newPassword)}
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>

                <Modal.Footer className="w-100 d-flex flex-row align-items-center">
                    <div className="w-100 double-btns d-flex flex-row align-items-center justify-content-center gap-2">
                        <Button
                            onClick={(e) => {
                                validate();
                                postData(e);
                            }}
                            disabled={btnLoader ? true : false}
                        >
                            {dataTransalte.popups.settings.submit}
                        </Button>
                        <Button onClick={onHide}>
                            {dataTransalte.popups.settings.close}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ResetPassModal;
