// Bootstrap
import Modal from "react-bootstrap/Modal";

const MainHeader = ({ dataTranslate, data, action, functions }) => {
    return (
        <Modal.Header className="d-flex align-items-center justify-content-center ">

            <Modal.Title
                id="contained-modal-title-vcenter"
                className="fs-2 d-flex flex-row gap-2"
            >
                <span>
                    {action === "add"
                        ? dataTranslate.add_title
                        : action === "update"
                            ? dataTranslate.update_title
                            : ""}
                </span>
                {action === "update" ? (
                    <span style={{ color: "red" }}>{data._id}</span>
                ) : (
                    ""
                )}
            </Modal.Title>

            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                functions.reset();
                functions.onHide();
                if (action === "update") {
                    functions.setActiveEditBtn(false);
                }
            }}></button>

        </Modal.Header>
    )
}

export default MainHeader;