import React, { useState, useRef, useEffect } from 'react';
import Message from "../../utils/modals/Message";
// ==== context =====
import { getCardsSetData } from '../../../context/api/cards/office/getCardsSetData';
import { getTableDataSet } from '../../../context/api/tables/office/getTableDataSet.js';

import {
    officeSwitcher_1
} from "../../utils/extra/cards/swichers.js";
import Cards from "../../dynamic-components/Cards.js";
import UsersTable from "../office/UsersTable.js";

import { Spinner } from 'react-bootstrap';
const Index = ({ cookies }) => {
    const initialMessageRes = {
        success: Boolean,
        message: String,
        inShow: false
    };


    const [showFullModal, setShowFullModal] = useState({
        isShow: false,
    });

    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const fetched = useRef(false);
    const cardAction = useRef();
    const [activeCard, setActiveCard] = useState("active");
    const [cardsData, setCardsData] = useState([]);
    const [refreshData, setRefreshData] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [tableLoader, setTableLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);

    // fetch card data 
    const fetchOnStart = async () => {
        fetched.current = false;
        // fetch cardData
        await getCardsSetData(setMessageResponse,
            cookies,
            setCardsData)
        // fetch table data
        await getTableDataSet(
            setMessageResponse,
            cookies,
            activeCard,
            setTableData,
            setTableLoader
        )
        fetched.current = true;
    }

    useEffect(() => {
        fetchOnStart();
    }, [refreshData]);

    if (!fetched.current) {
        return (
            <div className="page_loader">
                <Spinner animation="border" style={{ color: "#1F3D7D" }} />
            </div>
        );
    }

    const fetchTableData = async (key) => {
        setTableLoader(() => true);
        cardAction.current = key;
        await getTableDataSet(
            setMessageResponse,
            cookies,
            cardAction.current,
            setTableData,
            setTableLoader
        )
    }

    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />

            <div className="office_p" dir={"rtl"}>
                <Cards
                    pageClass="office_p"
                    dataCard1={cardsData}
                    switcher_1={officeSwitcher_1}
                    activeCard={activeCard}
                    setActiveCard={setActiveCard}
                    fetchTableData={fetchTableData}
                />

                <UsersTable
                    tableData={tableData}
                    loader={tableLoader}
                    setRefreshData={setRefreshData}
                    btnLoader={btnLoader}
                    setTableData={setTableData} />
            </div>
        </>
    );
}

export default Index;