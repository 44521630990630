import { loginApi } from "./loginApi";

export const loginSetData = async (
    credintial,
    setAuth,
    setMessageResponse,
    setCookie,
    setBtnLoader
) => {
    // fire request 
    const response = await loginApi(credintial);
    if (response.success) {
        // valid user 
        localStorage.setItem(
            '_affairs',
            response.res.data.data.affairs
        );
        localStorage.setItem(
            "_roles",
            response.res.data.data.roles
        );
        localStorage.setItem(
            "_permission",
            JSON.stringify(response.res.data.data.permission)
        );
        setCookie("_token", response.res.data.data.token);
        setAuth((prev) => ({
            ...prev,
            isAuthenticated: true,
            role: response.res.data.data.roles
        }))
        setBtnLoader(() => false);
    } else {
        const error = response.res;
        if (error.response) {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: error.response.data.message,
                isShow: true,
            }));
            setBtnLoader(() => false);
        } else {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: "Timeout",
                isShow: true,
            }));
            setBtnLoader(() => false);
        }
    }

}