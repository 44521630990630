import { getCardsApi } from "./getCardsApi";

export const getCardsSetData = async (
    setMessageResponse,
    cookies,
    setCardsData
) => {
    const response = await getCardsApi(cookies);
    if (response.success) {
        setCardsData(() => response.res.data.data);
    } else {
        const error = response.res;
        if (error.response) {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: error.response.data.message,
                isShow: true,
            }));
        } else {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: "Timeout",
                isShow: true,
            }));
        }
    }
};
