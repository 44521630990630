import React, { useContext } from "react";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    Navigate
} from "react-router-dom";
import { ContextApi } from "../context/ContextApi";
import { useCookies } from "react-cookie";
import Privateroutes from "./auth/Privateroutes";
// ======== Bootstrap ======
import { Spinner } from "react-bootstrap";

// ======== Pages ==========
import Login from "./pages/public/Login";
import NotFound from "./pages/public/NotFound.";
import Index from "./pages/home/index";
import Office from './pages/office/index';
import ForgetPass from "./pages/public/ForgetPass";


// ======== Layout ==========
import Header from './layout/Header';

const Main = () => {
    const { authentication, loading } = useContext(ContextApi);
    const [auth, setAuth] = authentication;
    const [cookies, setCookie, removeCookies] = useCookies();
    const role = localStorage.getItem("_roles");

    if (auth.isAuthenticated === null) {
        return (
            <div className="spiner d-flex flex-column align-items-center justify-content-center">
                <div className="page_loader">
                    <Spinner animation="border" style={{ color: "#1F3D7D" }} />
                </div>
            </div>
        );
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/">
                <Route element={<>
                    <Privateroutes auth={auth.isAuthenticated} />
                    <Header auth={auth}
                        setAuth={setAuth}
                        removeCookies={removeCookies}
                    /></>}>
                    < Route index element={<Index cookies={cookies} />} />
                    {role === "super-admin" ? (
                        <Route path="/office" element={<Office cookies={cookies} />} />
                    ) : (
                        <></>
                    )}
                </Route>
                {!auth.isAuthenticated ? (
                    <>
                        <Route path="login" element={<Login setAuth={setAuth} />} />
                        <Route path="/forget-pass" element={<ForgetPass />} />
                    </>
                ) : (
                    <Route path="login" element={<Navigate replace to="/" />} />
                )}

                {/* <Route path="*" element={<NotFound />} /> */}
                <Route path="*" element={<Navigate replace to="/login" />} />
            </Route>
        )
    )

    return (
        <RouterProvider router={router} />
    )
}

export default Main;