import React from "react";

const SearchBar = ({ field , searchValue, handleInputChange, handleSelectChange, options, setSearch }) => {
    return (
        <div className={"search-bar_ar ms-auto"}>


            <div className="selector">
                <select
                    onChange={handleSelectChange}
                >
                    {options.map((item, index) => {
                        return (
                            <option selected={index === 0 ? true : false} value={item.key} key={item.key}>
                                {item.value}
                            </option>
                        );
                    })}
                </select>
            </div>

            <div className="searcher">
                <input value={searchValue} onChange={handleInputChange} />
            </div>
        </div>
    );
};

export default SearchBar;
