import {
    createColumnHelper
} from "@tanstack/react-table";

import React, { useState, useMemo } from "react";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCirclePlus,
    faArrowRotateRight,
    faFileContract,
    faDownload,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import { useCookies } from "react-cookie";
// components
import SearchBar from "../../dynamic-components/SearchBar";
import SearchForm from "../../dynamic-components/SearchForm";
import DataTable from "../../dynamic-components/DataTable";
import { deleteUserSetData } from "../../../context/api/office/delete-user/deleteUserSetData";
import { isActiveUserSetData } from "../../../context/api/office/isActive-user/isActiveUserSetData";
import ConfirmModal from "../../utils/modals/office-modal/dynamic-modals/ConfirmModal";
import StatusConfirmModal from "../../utils/modals/office-modal/dynamic-modals/StatusConfirmModal";
import CreateUserModal from "../../utils/modals/office-modal/create-user/CreateUserModal";
import EditUserModal from "../../utils/modals/office-modal/edit-user/EditUserModal";

const UserTable = ({
    tableData,
    loader,
    setRefreshData,
    btnLoader,
    setTableData
}) => {
    const dataTranslate = require("../../../translate/arabic/data.json");
    const searchByData = dataTranslate.pages.office_table.table_head.search_by;


    const initialAddModal = {
        isShow: false,
        action: "",
        rowData: {},
    };

    const initialConfirmModal = {
        isShow: false,
        rowData: {},
    };
    const initialIsActiveConfirmModal = {
        isShow: false,
        action: "",
        rowData: {},
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };
    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };


    const [showAddModal, setShowAddModal] = useState(initialAddModal);
    const [confirmModal, setConfirmModal] = useState(initialConfirmModal);
    const [confirmBtnLoader, setConfirmBtnLoader] = useState(false);

    const [isActiveModal, setIsActiveModal] = useState(initialIsActiveConfirmModal);
    const [isActiveConfirmBtnLoader, setisActiveConfirmBtnLoader] = useState(false);

    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
    const [menuLoading, setMenuLoading] = useState(false);
    const [cookies] = useCookies();
    const [showEditModal, setShowEditModal] = useState(initialAddModal);
    const [activeSeachInput, setActiveSearchInput] = useState(false);
    const searchOptions = [
        { key: "email", value: searchByData.email },
        { key: "name", value: searchByData.affairs },
    ];

    const deleteAction = {
        title: dataTranslate.popups.office_p.delete.title,
        submit: dataTranslate.popups.office_p.delete.submit,
    };

    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("id", {
            header: dataTranslate.pages.office_table.table_body._id
        }),
        columnHelper.accessor("email", {
            header: dataTranslate.pages.office_table.table_body.email
        }),
        columnHelper.accessor("name", {
            header: dataTranslate.pages.office_table.table_body.affairs
        }),
        columnHelper.accessor("role_name", {
            header: dataTranslate.pages.office_table.table_body.role,
            cell: ({ cell }) => {
                return (
                    <div >
                        {(() => {
                            switch (cell.row.original.role_name) {
                                case "super-admin":
                                    return ("المشرف")
                                case "admin":
                                    return ("المسؤول")
                                case "staff":
                                    return ("فريق العمل")
                                case "user":
                                    return ("مستخدم اعتيادي")
                                default:
                                    return ("")
                            }
                        })()}
                    </div>
                )
            }
        }),
        columnHelper.accessor("isActive", {
            header: dataTranslate.pages.office_table.table_body.isActive,
            cell: ({ cell }) => {
                return (
                    <div style={cell.row.original.isActive == 0 ? { color: "#e65857", fontWeight: "500" } : { color: "#6cda3a", fontWeight: "500" }}>
                        {cell.row.original.isActive == 0 ? "غير فعال" : "فعال"}
                    </div>
                )
            }
        }),
        columnHelper.accessor("action", {
            header: dataTranslate.pages.office_table.table_body.action,
            cell: ({ cell }) => {
                return (
                    <div className="d-flex gap-3">

                        <button
                            className="office_p-table-table-body-btn"
                            onClick={() =>
                                setShowEditModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    action: "update",
                                    rowData: cell.row.original,
                                }))
                            }
                        >
                            {dataTranslate.pages.office_table.table_body.edit}
                        </button>
                        {/* <button
                            className="home_p-table-table-body-btn"
                            onClick={() =>
                                setConfirmModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    rowData: cell.row.original,
                                }))
                            }
                        >
                            {dataTranslate.pages.office_table.table_body.delete}
                        </button> */}
                        <button
                            className="office_p-table-table-body-btn"
                            style={
                                { width: "100px" }
                            }
                            onClick={() =>
                                setIsActiveModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    action: (cell.row.original.isActive === 0) ? 1 : 0,
                                    rowData: cell.row.original,
                                }))
                            }
                        >
                            {cell.row.original.isActive === 0
                                ? dataTranslate.pages.office_table.table_body.unBlock
                                : dataTranslate.pages.office_table.table_body.block}
                        </button>
                    </div >
                );
            }
        }),

    ]
    // to keep the selected column field
    const [field, setField] = useState("fullName");

    // to keep the input search value
    const [searchValue, setSearchValue] = useState("");

    // required by react-table for filtering purposes
    const [columnFilters, setColumnFilters] = useState();

    const handleSelectChange = (e) => {
        setColumnFilters([]);
        setSearchValue("");
        setField(e.target.value);
    };

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    if (loader) {
        return (
            <div className="page_loader">
                <Spinner animation="border" style={{ color: "#1F3D7D" }} />
            </div>
        );
    }

    const deleteUser = async (id) => {
        setConfirmBtnLoader(() => true);
        await deleteUserSetData(
            setMessageResponse,
            cookies,
            id,
            setSuccessMessage
        );
        setConfirmBtnLoader(() => false);
    }
    const isActiveUser = async (id, isActive) => {
        setisActiveConfirmBtnLoader(() => true);
        await isActiveUserSetData(
            setMessageResponse,
            cookies,
            id,
            setSuccessMessage,
            isActive
        );
        setisActiveConfirmBtnLoader(() => false);
    }
    return (
        <div className="home_p-table w-100 d-flex flex-column align-items-center">
            <div className="home_p-table-nav w-100 m-3 px-1">
                <Stack direction="horizontal" gap={2} className="align-items-center">
                    <SearchForm
                        field={field}
                        searchValue={searchValue}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        options={searchOptions}
                    />

                    <div className="d-flex gap-2">
                        <div
                            className="home_p-table-nav-btn"
                            onClick={() =>
                                setShowAddModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    action: "add",
                                }))
                            }
                        >
                            <span>{dataTranslate.pages.office_table.table_head.add}</span>
                            <FontAwesomeIcon
                                icon={faCirclePlus}
                                size="lg"
                                style={{ color: "#747B82" }}
                            />
                        </div>
                        {/* <div
                            className="home_p-table-nav-btn"
                        // onClick={() =>
                        //     setShowAddModal((prev) => ({
                        //         ...prev,
                        //         isShow: true,
                        //         action: "add",
                        //     }))
                        // }
                        >
                            <span>{dataTranslate.pages.office_table.table_head.report}</span>
                            <FontAwesomeIcon
                                icon={faDownload}
                                size="lg"
                                style={{ color: "#747B82" }}
                            />
                        </div> */}

                        <div
                            className={
                                "home_p-table-nav-btn"
                            }
                            onClick={() => {
                                setRefreshData((prev) => prev + 1);
                            }}
                        >
                            <span>{dataTranslate.pages.office_table.table_head.refresh}</span>
                            <FontAwesomeIcon
                                icon={faArrowRotateRight}
                                size="lg"
                                style={{ color: "#747B82" }}
                            />
                        </div>
                    </div>
                </Stack>
            </div>

            <div className="home_p-table-table w-100 m-0 px-1">
                <StatusConfirmModal
                    show={isActiveModal.isShow}
                    onHide={() => setIsActiveModal(initialIsActiveConfirmModal)}
                    post={isActiveUser}
                    data={isActiveModal.rowData}
                    dataTranslate={dataTranslate}
                    setRefreshData={setRefreshData}
                    btnLoader={isActiveConfirmBtnLoader}
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                    isActive={isActiveModal.action}
                />

                <ConfirmModal
                    show={confirmModal.isShow}
                    onHide={() => setConfirmModal(initialConfirmModal)}
                    post={deleteUser}
                    data={confirmModal.rowData}
                    dataTranslate={dataTranslate}
                    setRefreshData={setRefreshData}
                    action={deleteAction}
                    btnLoader={confirmBtnLoader}
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                />
                <EditUserModal
                    show={showEditModal.isShow}
                    onHide={() => {
                        setShowEditModal(initialAddModal)
                        setMenuLoading(false);
                    }}
                    action={showEditModal.action}
                    data={showEditModal.rowData}
                    setRefreshData={setRefreshData}
                    dataTranslate={dataTranslate.popups.add_user}
                />

                <CreateUserModal
                    show={showAddModal.isShow}
                    onHide={() => setShowAddModal(initialAddModal)}
                    dataTranslate={dataTranslate.popups.add_user}
                    action={showAddModal.action}
                    data={showAddModal.rowData}
                    setRefreshData={setRefreshData}
                    menuLoading={menuLoading}
                />

                <DataTable
                    columns={columns}
                    data={tableData}
                    loader={loader}
                    searchValue={searchValue}
                    field={field}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    handleSelectChange={handleSelectChange}
                    handleInputChange={handleInputChange}
                />
            </div>
        </div>
    )

}


export default UserTable;
