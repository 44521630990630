import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SuccessRes = ({ res, onHide, dataTranslate }) => {
    const data =
        res.type === "add"
            ? dataTranslate.res_message.add
            : dataTranslate.res_message.update;
    return (
        <>
            <Modal.Header className="d-flex align-items-center justify-content-center "></Modal.Header>

            <Modal.Body className="mx-5 ">
                <div
                    className="d-flex flex-column align-items-center gap-3 "
                    dir={"rtl"}
                >
                    <div className="d-flex flex-column d-flex flex-column align-items-center gap-1">
                        <h1>{data.email}</h1>
                        <span className="p-3 h3" style={{ color: "#e65857" }}>
                            {res.data.email}
                        </span>
                    </div>
                    <div className="d-flex flex-column d-flex flex-column align-items-center gap-1">
                        <h1>{data.affairs}</h1>
                        <span className="p-3 h3" style={{ color: "#e65857" }}>
                            {res.data.name}
                        </span>
                    </div>

                    <div className="d-flex flex-column d-flex flex-column align-items-center gap-1">
                        <h1>{data.role}</h1>
                        <span className="p-3 h3" style={{ color: "#e65857" }}>
                            {(() => {
                                switch (res.data.role_name) {
                                    case "super-admin":
                                        return ("المشرف")
                                    case "admin":
                                        return ("المسؤول")
                                    case "staff":
                                        return ("فريق العمل")
                                    case "user":
                                        return ("مستخدم اعتيادي")
                                    default:
                                        return ("")
                                }
                            })()}
                        </span>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer
                className="w-100 d-flex flex-row align-items-center"
                dir="ltr"
            >
                <div className="w-100 double-btns d-flex flex-row align-items-center justify-content-center gap-2">
                    <Button onClick={onHide} style={{ backgroundColor: "#e65857" }}>
                        {dataTranslate.res_message.close}
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export default SuccessRes;
