import React, { useState } from "react";
import { useValidator } from "react-joi";
import { useCookies } from "react-cookie";
// ======= Bootstrp Components=======
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// components
import Logo from "../../../assets/img/Logo.png";
import Message from "../../utils/modals/Message";
import { loginSchema } from "../../joi/loginSchema";
import { joiErrorMessage } from "../../utils/extra/joiErrorMessage";
import { loginSetData } from "../../../context/api/login/loginSetData";
import { NavLink } from "react-router-dom";


const Login = ({ setAuth }) => {
    const data = require(`../../../translate/arabic/data.json`);
    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };
    const [cookies, setCookie] = useCookies();
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [btnLoader, setBtnLoader] = useState(false);

    // ====== joi ======
    const schema = loginSchema().schema;
    const { state, setData, setExplicitField, validate } = useValidator(schema);

    const updateUserName = (e) => {
        setData((old) => ({
            ...old,
            email: e.target.value,
        }));
    }

    const updatePassowrd = (e) => {
        setData((old) => ({
            ...old,
            password: e.target.value
        }))
    }

    const postData = (e) => {
        e.preventDefault();
        const credintial = {
            email: state.$data.email,
            password: state.$data.password,
        };
        if (state.$all_source_errors.length == 0) {
            setBtnLoader(() => true);
            return loginSetData(
                credintial,
                setAuth,
                setMessageResponse,
                setCookie,
                setBtnLoader
            );
        }
    }
    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />

            <div className="login_p d-flex flex-column align-items-center justify-content-center">
                <div className="login_p-form d-flex flex-column align-items-center">
                    <img src={Logo} width={105} alt="مجلس الخدمة العامة الاتحادي" />
                    <h3>منصة تسجيل</h3>
                    <h3>الشهادات العليا والأوائل </h3>

                    <Form
                        className="w-100 px-4 pb-4"
                        dir="rtl"
                        onSubmit={(e) => {
                            validate();
                            postData(e);
                        }}
                    >
                        <Form.Group className="my-5" controlId="formBasicEmail">
                            <Form.Label>البريد الاكتروني</Form.Label>
                            <Form.Control
                                className="login_p-form-inputs"
                                type="text"
                                placeholder="ادخل البريد الاكتروني"
                                onChange={updateUserName}
                                onBlur={() => setExplicitField("email", true)}
                            />
                            <Form.Text style={{ color: "red" }}>
                                {joiErrorMessage(state.$errors.email)}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-5" controlId="formBasicPassword">
                            <div className="login-pass-label w-100 d-flex flex-row justify-content-between">
                                <Form.Label>الرمز السري</Form.Label>
                                <NavLink to="/forget-pass">{data.pages.login_p.forget_pass}</NavLink>
                            </div>
                            <Form.Control
                                className="login_p-form-inputs"
                                type="password"
                                placeholder={"ادخل الرمز السري"}
                                onChange={updatePassowrd}
                                autoComplete="true"
                                onBlur={() => setExplicitField("password", true)}

                            />
                            <Form.Text style={{ color: "red" }}>
                                {joiErrorMessage(state.$errors.password)}
                            </Form.Text>
                        </Form.Group>

                        <Button
                            className="login-btn w-100"
                            variant="primary"
                            type="submit"
                            disabled={btnLoader ? true : false}
                        >
                            {"سجل دخول الان"}
                        </Button>
                    </Form>
                </div>
                <footer>
                    <div className="copyright" style={{ color: "black" }}>
                        &copy; مجلس الخدمة العامة الاتحادي
                    </div>
                </footer>
            </div>


        </>
    );
};

export default Login;
