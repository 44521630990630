import axios from "axios";

export const editUserApi = async (credintial, cookies, id, data) => {
    const token = cookies._token;
    delete data["isActive"];
    delete data["id"];
    let payload = JSON.stringify({ ...data, ...credintial });

    let config = {
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_API}/users_update/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        data: payload,
        timeout: 15000,
    };

    try {
        const res = await axios(config);
        return { success: res.data.success, res: res };
    } catch (error) {
        return { success: false, res: error };
    }
};
