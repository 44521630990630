import React, { useState } from "react";
import Message from "../../Message";
import SuccessRes from "./SuccessRes";
import { addUserSchmea } from "../../../../joi/addUserSchema";
import { addUserSetData } from "../../../../../context/api/office/add-user/addUserSetData";
// import { updateCustomerSchema } from "../../../joi/customerSchema";
// import { addGraduateSetData } from "../../../../context/api/graduate/add-graduate/addGraduateSetData";
// import { updateCustomerSetData } from "../../../../context/api/cutomer/update-customer/updateCustomerSetData";
import { useCookies } from "react-cookie";
import Joi from 'joi';

// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";

// shared component
import MainFooter from '../shared/MainFooter';
import MainHeader from '../shared/MainHeader';

const CreateUserModal = ({
    show,
    onHide,
    dataTranslate,
    action,
    data,
    setRefreshData,
    menuLoading,
}) => {
    const schema = addUserSchmea();

    const initUserInfo = {
        email: "",
        name: "",
        role_name: "",
        password: ""
    };

    const [userInfo, setUserInfo] = useState(initUserInfo);

    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };

    const [errors, setErrors] = useState({});
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
    const [cookies] = useCookies();
    const [btnLoader, setBtnLoader] = useState(false);
    const createUser = () => {
        var objectSchema = Joi.object().keys(schema);
        const result = objectSchema.validate(userInfo, { abortEarly: false });
        const { error } = result;
        if (!error) {
            // pass the payload to the context API to do the request
            setBtnLoader(() => true);
            return addUserSetData(
                setMessageResponse,
                cookies,
                userInfo,
                setSuccessMessage,
                setBtnLoader,
            );
        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            setErrors(errorData);
            return errorData;
        }
    }
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        let errorData = { ...errors };
        const errorMessage = validateProperty(e);
        if (errorMessage) {
            errorData[name] = errorMessage;
        } else {
            delete errorData[name];
        }
        let userData = { ...userInfo };
        userData[name] = value;
        setUserInfo(userData);
        setErrors(errorData);
    }

    const validateProperty = (event) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = schema[name];
        const result = subSchema.validate(value);
        const { error } = result;
        return error ? error.details[0].message : null;
    };

    const reset = () => {
        setUserInfo(initUserInfo)
        setErrors({
        })
    }

    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />
            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
                dir={"rtl"}
            >
                {successMessage.success ? (
                    <SuccessRes
                        dataTranslate={dataTranslate}
                        onHide={async () => {
                            await onHide();
                            setSuccessMessage(initialSuccessMessage);
                            setRefreshData((prev) => prev + 1);
                        }}
                        res={successMessage}
                    />
                ) : (
                    <>
                        <MainHeader dataTranslate={dataTranslate} data={data} action={action} functions={{
                            reset, onHide
                        }} />
                        <Modal.Body>
                            <div dir={"rtl"}>
                                <Card>
                                    <Card.Header>
                                        {dataTranslate.userInfo}
                                    </Card.Header>
                                    <Card.Body>
                                        <Container>
                                            <Row className="mb-lg-3">
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.email
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="email"
                                                            name="email"
                                                            placeholder={
                                                                dataTranslate.email_p
                                                            }
                                                            onChange={handleOnChange}
                                                            value={userInfo.email}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.email}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.password
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="password"
                                                            placeholder={
                                                                dataTranslate.password_p
                                                            }
                                                            onChange={handleOnChange}
                                                            value={userInfo.password}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.password}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.affairs
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="name"
                                                            placeholder={
                                                                dataTranslate.affairs_p
                                                            }
                                                            onChange={handleOnChange}
                                                            value={userInfo.name}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.name}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.role
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Select
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="role_name"
                                                            value={userInfo.role_name}
                                                            onChange={handleOnChange}
                                                        >
                                                            <option hidden>
                                                                {dataTranslate.choose}
                                                            </option>
                                                            {dataTranslate.role_list.map((item, index) => {
                                                                return <option value={item.value} key={index}>
                                                                    {item.title}
                                                                </option>
                                                            })}
                                                        </Form.Select>

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.role_name}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </Card.Body>
                                </Card>


                            </div>
                        </Modal.Body>
                        <MainFooter functions={{
                            createUser, reset, onHide,
                        }} status={{ menuLoading, btnLoader }} dataTranslate={dataTranslate} action={action} />
                    </>
                )}
            </Modal>
        </>
    )
}

export default CreateUserModal;