import {
    createColumnHelper
} from "@tanstack/react-table";

import React, { useState, useMemo } from "react";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCirclePlus,
    faArrowRotateRight,
    faFileContract,
    faDownload,
    faSearch
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import { useCookies } from "react-cookie";
// components
import SearchBar from "../../dynamic-components/SearchBar";
import SearchForm from "../../dynamic-components/SearchForm";
import DataTable from "../../dynamic-components/DataTable";
import CreateGraduateModal from "../../utils/modals/graduate-modal/CreateGraduateModal";
import { deleteGraduateSetData } from "../../../context/api/graduate/delete-graduate/deleteGraduateSetData";
import ConfirmModal from "../../dynamic-components/ConfirmModal";
import ShowGraduateModal from "../../utils/modals/graduate-modal/show-graduate/ShowGradauteModal";
import EditGraduateModal from "../../utils/modals/graduate-modal/edit-graduate/EditGraduateModal";
import SearchGraduateModal from "../../utils/modals/graduate-modal/search-graduate/SearchGraduateModal";
// resourcs
import countries from "../../../assets/shared/countries";
import iraqiUniversities from "../../../assets/shared/iraqiUniversities";
import getLastFiveYears from "../../../assets/shared/getLastFiveYears";

const HomeTable = ({
    tableData,
    loader,
    setRefreshData,
    btnLoader,
    setTableData,
    getCsv
}) => {
    // get data file 
    const dataTranslate = require("../../../translate/arabic/data.json");
    const searchByData = dataTranslate.pages.table.table_head.search_by;

    const initialAddModal = {
        isShow: false,
        action: "",
        rowData: {},
    };

    const initialConfirmModal = {
        isShow: false,
        rowData: {},
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };
    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };

    const initialShowModal = {
        isShow: false,
        rowData: {}
    }
    const initialSearchModal = {
        isShow: false
    }

    const [searchModal, setSearchModal] = useState(initialSearchModal);

    const [searchData, setSearchData] = useState({

    });

    const [showAddModal, setShowAddModal] = useState(initialAddModal);
    const [confirmModal, setConfirmModal] = useState(initialConfirmModal);
    const [confirmBtnLoader, setConfirmBtnLoader] = useState(false);
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);

    const [menuLoading, setMenuLoading] = useState(false);
    const [cookies] = useCookies();


    const [showReadModal, setShowReadModal] = useState(initialShowModal)
    const [showEditModal, setShowEditModal] = useState(initialAddModal);
    const [activeSeachInput, setActiveSearchInput] = useState(false);

    const action = {
        title: dataTranslate.popups.home_p.delete_modal.title,
        submit: dataTranslate.popups.home_p.delete_modal.submit,
    };


    const searchOptions = [
        { key: "fullName", value: searchByData.name },
        { key: "motherName", value: searchByData.mother_name },
    ];

    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor("_id", {
            header: dataTranslate.pages.table.table_body._id
        }),
        columnHelper.accessor("fullName", {
            header: dataTranslate.pages.table.table_body.fullName
        }),
        columnHelper.accessor("motherFullName", {
            header: dataTranslate.pages.table.table_body.motherName
        }),
        columnHelper.accessor("scholarship", {
            header: dataTranslate.pages.table.table_body.scholarship,
            cell: ({ cell }) => {
                cell.row.original.scholarship = (cell.row.original.scholarship).toString();
                return (
                    <div style={cell.row.original.scholarship == "false" ? { color: "red" } : { color: "green" }}>
                        {cell.row.original.scholarship == "false" ? "كلا" : "نعم"}
                    </div>
                )
            }
        }),
        columnHelper.accessor("universityNameAR", {
            header: dataTranslate.pages.table.table_body.university
        }),
        columnHelper.accessor("collegeName", {
            header: dataTranslate.pages.table.table_body.college
        }),
        columnHelper.accessor("departmentName", {
            header: dataTranslate.pages.table.table_body.department
        }),
        columnHelper.accessor("studyType", {
            header: dataTranslate.pages.table.table_body.type
        }),
        columnHelper.accessor("degree", {
            header: dataTranslate.pages.table.table_body.degree,
            cell: ({ cell }) => {
                return (
                    <div >
                        {(() => {
                            switch (cell.row.original.id) {
                                case 1:
                                    return dataTranslate.popups.add_graduate.degree_type.pgd;
                                case 2:
                                    return dataTranslate.popups.add_graduate.degree_type.bsc;
                                case 3:
                                    return dataTranslate.popups.add_graduate.degree_type.msc;
                                case 4:
                                    return dataTranslate.popups.add_graduate.degree_type.phd;
                                default:
                                    return;
                            }
                        })()}
                    </div>
                )
            }
        }),
        columnHelper.accessor("graduationRate", {
            header: dataTranslate.pages.table.table_body.rate
        }),
        columnHelper.accessor("action", {
            header: dataTranslate.pages.table.table_body.action,
            cell: ({ cell }) => {
                return (
                    <div className="d-flex gap-3">

                        <button
                            className="home_p-table-table-body-btn"
                            onClick={() =>
                                setShowReadModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    rowData: cell.row.original,
                                }))
                            }
                        >
                            {dataTranslate.pages.table.table_body.show}
                        </button>
                        <button
                            className="home_p-table-table-body-btn"
                            onClick={() =>
                                setShowEditModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    action: "update",
                                    rowData: cell.row.original,
                                }))
                            }
                        >
                            {dataTranslate.pages.table.table_body.edit}
                        </button>
                        <button
                            className="home_p-table-table-body-btn"
                            onClick={() =>
                                setConfirmModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    rowData: cell.row.original,
                                }))
                            }
                        >
                            {dataTranslate.pages.table.table_body.delete}
                        </button>
                    </div>
                );
            }
        }),

    ]

    // to keep the selected column field
    const [field, setField] = useState("fullName");

    // to keep the input search value
    const [searchValue, setSearchValue] = useState("");

    // required by react-table for filtering purposes
    const [columnFilters, setColumnFilters] = useState();

    const handleSelectChange = (e) => {
        setColumnFilters([]);
        setSearchValue("");
        setField(e.target.value);
    };

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    if (loader) {
        return (
            <div className="page_loader">
                <Spinner animation="border" style={{ color: "#1F3D7D" }} />
            </div>
        );
    }

    const deleteGraduate = async (id) => {
        setConfirmBtnLoader(() => true);
        await deleteGraduateSetData(
            setMessageResponse,
            cookies,
            id,
            setSuccessMessage
        );
        setConfirmBtnLoader(() => false);
    }
    return (
        <div className="home_p-table w-100 d-flex flex-column align-items-center">
            <div className="home_p-table-nav w-100 m-3 px-1">
                <Stack direction="horizontal" gap={2} className="align-items-center">
                    {/* <SearchForm
                        field={field}
                        searchValue={searchValue}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        options={searchOptions}
                    /> */}

                    <div className="d-flex gap-2 ms-auto">
                        <div
                            className={`home_p-table-nav-btn ${activeSeachInput ? "active" : ""}`}
                            onClick={() =>
                                setSearchModal({ isShow: true })
                            }
                        >
                            <span>{dataTranslate.pages.table.table_head.search}</span>
                            <FontAwesomeIcon
                                icon={faSearch}
                                size="lg"
                                style={{ color: activeSeachInput ? "#FFFFFF" : "#747B82" }}
                            />
                        </div>
                    </div>

                    <div className="d-flex gap-2">
                        <div
                            className="home_p-table-nav-btn"
                            onClick={() =>
                                setShowAddModal((prev) => ({
                                    ...prev,
                                    isShow: true,
                                    action: "add",
                                }))
                            }
                        >
                            <span>{dataTranslate.pages.table.table_head.add}</span>
                            <FontAwesomeIcon
                                icon={faCirclePlus}
                                size="lg"
                                style={{ color: "#747B82" }}
                            />
                        </div>
                        <div
                            className="home_p-table-nav-btn"
                            onClick={() => getCsv()}
                        // onClick={() =>
                        //     setShowAddModal((prev) => ({
                        //         ...prev,
                        //         isShow: true,
                        //         action: "add",
                        //     }))
                        // }
                        >
                            {btnLoader ? (
                                <Spinner animation="border" style={{ color: "#1F3D7D" }} />
                            ) : (
                                <span>{dataTranslate.pages.table.table_head.report}</span>
                            )}
                            <FontAwesomeIcon
                                icon={faDownload}
                                size="lg"
                                style={{ color: "#747B82" }}
                            />
                        </div>

                        <div
                            className={
                                "home_p-table-nav-btn"
                            }
                            onClick={() => {
                                setRefreshData((prev) => prev + 1);
                            }}
                        >
                            <span>{dataTranslate.pages.table.table_head.refresh}</span>
                            <FontAwesomeIcon
                                icon={faArrowRotateRight}
                                size="lg"
                                style={{ color: "#747B82" }}
                            />
                        </div>
                    </div>
                </Stack>


            </div>

            <div className="home_p-table-table w-100 m-0 px-1">

                <SearchGraduateModal
                    show={searchModal.isShow}
                    onHide={() => {
                        setSearchModal(false)
                    }}
                    search={searchData}
                    dataTranslate={dataTranslate}
                    setSearchData={setSearchData}
                    countries={countries}
                    iraqiUniversities={iraqiUniversities}
                    getLastFiveYears={getLastFiveYears()}
                    setTableData={setTableData}
                    setRefreshData={setRefreshData}
                    setActiveSearchInput={setActiveSearchInput}
                />

                <EditGraduateModal
                    show={showEditModal.isShow}
                    onHide={() => {
                        setShowEditModal(initialAddModal)
                        setMenuLoading(false);
                    }}
                    action={showEditModal.action}
                    data={showEditModal.rowData}
                    setRefreshData={setRefreshData}
                    dataTranslate={dataTranslate.popups.add_graduate}
                    countries={countries}
                    iraqiUniversities={iraqiUniversities}
                    getLastFiveYears={getLastFiveYears()}
                />

                <ShowGraduateModal
                    show={showReadModal.isShow}
                    onHide={() => {
                        setShowReadModal(initialShowModal)
                    }}
                    data={showReadModal.rowData}
                    dataTranslate={dataTranslate}
                />

                <ConfirmModal
                    show={confirmModal.isShow}
                    onHide={() => setConfirmModal(initialConfirmModal)}
                    post={deleteGraduate}
                    data={confirmModal.rowData}
                    dataTranslate={dataTranslate}
                    setRefreshData={setRefreshData}
                    action={action}
                    btnLoader={confirmBtnLoader}
                    successMessage={successMessage}
                    setSuccessMessage={setSuccessMessage}
                />

                <CreateGraduateModal
                    show={showAddModal.isShow}
                    onHide={() => setShowAddModal(initialAddModal)}
                    dataTranslate={dataTranslate.popups.add_graduate}
                    action={showAddModal.action}
                    data={showAddModal.rowData}
                    setRefreshData={setRefreshData}
                    menuLoading={menuLoading}
                    countries={countries}
                    iraqiUniversities={iraqiUniversities}
                    getLastFiveYears={getLastFiveYears()}
                />

                <DataTable
                    columns={columns}
                    data={tableData}
                    loader={loader}
                    searchValue={searchValue}
                    field={field}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    handleSelectChange={handleSelectChange}
                    handleInputChange={handleInputChange}
                />
            </div>
        </div>
    )
}

export default HomeTable;