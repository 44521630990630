import Joi from "joi";

export const forgetPassSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);

    const schema = {
        initialData: {
            email: null,
        },
        schema: Joi.object({
            email: Joi.string()
                .email({ tlds: { allow: false } })
                .required()
                .messages({
                    "string.empty": joiMessage.common.required,
                    "string.base": joiMessage.forget_pass.email.case1,
                    "string.email": joiMessage.forget_pass.email.case1,
                }),
        }),
        explicitCheck: {
            email: false,
        },
        validationOptions: {
            abortEarly: true,
        },
    };
    return { schema: schema };
};

export const verifyForgetPassSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);

    const schema = {
        initialData: {
            otp: null,
            newPassword: null,
        },
        schema: Joi.object({
            otp: Joi.string().min(6).max(6).messages({
                "string.empty": joiMessage.common.required,
                "string.base": joiMessage.forget_pass.verify_forget_pass.otp.invalid,
                "string.min": joiMessage.forget_pass.verify_forget_pass.otp.min,
                "string.max": joiMessage.forget_pass.verify_forget_pass.otp.max,
            }),
            newPassword: Joi.string()
                .required()
                .regex(/^(?=.*\d)(?=.*[a-z]).{8,}$/)
                .min(8)
                .max(40)
                .messages({
                    "string.empty": joiMessage.common.required,
                    "string.base":
                        joiMessage.forget_pass.verify_forget_pass.new_pass.invalid,
                    "string.pattern.base":
                        joiMessage.forget_pass.verify_forget_pass.new_pass.case1,
                    "string.min": joiMessage.forget_pass.verify_forget_pass.new_pass.min,
                    "string.max": joiMessage.forget_pass.verify_forget_pass.new_pass.max,
                }),
        }),
        explicitCheck: {
            otp: false,
            newPassword: false,
        },
        validationOptions: {
            abortEarly: true,
        },
    };
    return { schema: schema };
};