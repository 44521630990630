// Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const MainFooter = ({ functions, status, dataTranslate, action }) => {
    return (
        <Modal.Footer
            className="w-100 d-flex flex-row align-items-center"
            dir="ltr"
            style={{ zIndex: '1001' }}
        >
            <div className="w-100 double-btns d-flex flex-row align-items-center justify-content-center gap-2">
                <Button
                    onClick={action == "update" ? (e) => {
                        functions.editUser();
                    } : (e) => {
                        functions.createUser();
                    }}
                    // disabled={(!status.menuLoading || status.btnLoader) ? true : false}
                    disabled={status.btnLoader || status.activeEditBtn === false}
                // disabled={status.btnLoader ? true : false}
                // className="disableBTN"
                >
                    {action == "update" ? dataTranslate.update_btn : dataTranslate.add_btn}
                </Button>

                <Button
                    onClick={() => {
                        functions.reset();
                        functions.onHide();
                        if (action === "update") {
                            functions.setActiveEditBtn(false);
                        }
                    }}
                >
                    {dataTranslate.close}
                </Button>
            </div>
        </Modal.Footer>
    )
}

export default MainFooter;