import axios from "axios";

export const getCardsApi = async (cookies) => {
    const token = cookies._token;

    let config = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_API}/userSummary`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },

        timeout: 15000,
    };

    try {
        const res = await axios(config);
        return { success: res.data.success, res: res };
    } catch (error) {
        return { success: false, res: error };
    }
};
