import axios from "axios";

export const resetPassApi = async (credintial, cookies) => {
  const token = cookies._token;
  let data = JSON.stringify({
    newPassword: credintial.newPassword,
  });

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_BASE_API}/rest-password`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
    timeout: 15000,
  };

  try {
    const res = await axios(config);
    return { success: res.data.success, res: res };
  } catch (error) {
    return { success: false, res: error };
  }
};
