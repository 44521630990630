const iraqiUniversities = [
    { "id": "1", "universityNameAR": "جامعة بغداد" },
    { "id": "2", "universityNameAR": "الجامعة التكنولوجية" },
    { "id": "3", "universityNameAR": "جامعة البصره" },
    { "id": "4", "universityNameAR": "جامعة الموصل" },
    { "id": "5", "universityNameAR": "جامعة الكوفه" },
    { "id": "6", "universityNameAR": "جامعة ديالى" },
    { "id": "7", "universityNameAR": "جامعة بابل" },
    { "id": "8", "universityNameAR": "جامعة تكريت" },
    { "id": "9", "universityNameAR": "الجامعة المستنصرية" },
    { "id": "10", "universityNameAR": "جامعة القادسية" },
    { "id": "11", "universityNameAR": "جامعة الانبار" },
    { "id": "12", "universityNameAR": "جامعة ذي قار" },
    { "id": "13", "universityNameAR": "جامعة واسط" },
    { "id": "14", "universityNameAR": "جامعة ميسان" },
    { "id": "15", "universityNameAR": "جامعة النجف" },
    { "id": "16", "universityNameAR": "جامعة كربلاء" },
    { "id": "17", "universityNameAR": "جامعة دهوك" },
    { "id": "18", "universityNameAR": "جامعة كركوك" },
    { "id": "19", "universityNameAR": "جامعة المثنى" },
    { "id": "20", "universityNameAR": "جامعه صلاح الدين" },
    { "id": "21", "universityNameAR": "جامعة سليمانية" },
    { "id": "22", "universityNameAR": "جامعة نينوى" },
    { "id": "23", "universityNameAR": "جامعة الفلوجه" },
    { "id": "24", "universityNameAR": "جامعة سامراء" }
]

export default iraqiUniversities;