import axios from "axios";

export const isActiveUserApi = async (id, cookies, isActive) => {
    const token = cookies._token;

    let data = JSON.stringify({
        "isActive": isActive
    });

    let config = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_API}/block-or-unblock/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        data: data,
        timeout: 15000,
    };

    try {
        const res = await axios(config);
        return { success: res.data.success, res: res };
    } catch (error) {
        return { success: false, res: error };
    }
};
