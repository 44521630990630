import Joi from "joi";

export const loginSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);

    const schema = {
        initialData: {
            email: null,
            password: null,
        },
        schema: Joi.object({
            email: Joi.string()
                .email({ tlds: { allow: false } })
                .min(8)
                .max(40)
                .required()
                .messages({
                    "string.empty": joiMessage.common.required,
                    "string.base": joiMessage.login.userName.case1,
                    "string.min": joiMessage.login.userName.min,
                    "string.max": joiMessage.login.userName.max,
                    "string.email": joiMessage.login.userName.email
                }),
            password: Joi.string()
                .required()
                // .regex(/^(?=.*\d)(?=.*[a-z]).{8,}$/)
                .min(8)
                .max(40)
                .messages({
                    "string.empty": joiMessage.common.required,
                    "string.base": joiMessage.login.password.case1,
                    "string.pattern.base": joiMessage.login.password.case2,
                    "string.min": joiMessage.login.password.min,
                    "string.max": joiMessage.login.password.max,
                }),
        }),
        explicitCheck: {
            email: false,
            password: false,
        },
        validationOptions: {
            abortEarly: true,
        },
    };
    return { schema: schema };
};
