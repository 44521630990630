import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faArrowLeft,
    faAnglesRight,
    faAnglesLeft,
} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    lastPage,
    pageLength
}) => {
    return (
        <div className="pagination d-flex align-items-center justify-content-between gap-3">
            <div className="pagination-section1 d-flex flex-row align-items-center gap-3">
                <div
                    dir="rtl"
                    className="pagination-section1-arrows d-flex flex-row align-items-center justify-content-between gap-1"
                >
                    <button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                        style={!canPreviousPage ? { cursor: "no-drop" } : {}}
                    >
                        <FontAwesomeIcon icon={faAnglesRight} color="#1F3D7D" />
                    </button>

                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        style={!canPreviousPage ? { cursor: "no-drop" } : {}}
                    >
                        <FontAwesomeIcon icon={faArrowRight} color="#1F3D7D" />
                    </button>

                    <span dir="ltr">
                        <strong>
                            {pageIndex + 1} of {pageLength}
                        </strong>
                    </span>

                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage()}
                        // style={!canNextPage ? { cursor: "no-drop" } : {}}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} color="#1F3D7D" />
                    </button>

                    <button
                        onClick={() => lastPage()}
                        disabled={!canNextPage()}
                        style={!canNextPage ? { cursor: "no-drop" } : {}}
                    >
                        <FontAwesomeIcon icon={faAnglesLeft} color="#1F3D7D" />
                    </button>
                </div>
            </div>

            <div className="pagination-section2 d-flex flex-row align-items-center gap-3">
                {/* <span>
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span> */}
                {/* <select
                    value={pageSize}
                    onChange={(e) => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select> */}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Pagination;
