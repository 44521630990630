import Joi from "joi";

export const searchGraduateSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);
    const schema = {
        userName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only English letters and spaces
            if (!/^[A-Za-z0-9-]+$/.test(value)) {
                return helpers.message(joiMessage.common.search_english_number_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_english);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        firstName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        secondName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        thirdName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        fourthName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        sureName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        motherFirstName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        motherSecondName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        motherThirdName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.name.base,
            "string.min": joiMessage.add_graduate_modal.name.min,
            "string.max": joiMessage.add_graduate_modal.name.max,
        }),
        scholarship: Joi.string().valid("true", "false").optional().messages({
            "boolean.base": joiMessage.common.required,
            "any.only": joiMessage.add_graduate_modal.scholarship.valid
        }),
        country: Joi.string().min(2).max(100).optional().messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.country.base,
            "string.min": joiMessage.add_graduate_modal.country.min,
            "string.max": joiMessage.add_graduate_modal.country.max,
        }),
        degree: Joi.string().optional().valid('bsc', 'pgd', 'msc', 'phd').messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.degree.base,
            "any.only": joiMessage.add_graduate_modal.degree.valid
        }),
        universityNameAR: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(100).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.universityNameAR.base,
            "string.min": joiMessage.add_graduate_modal.universityNameAR.min,
            "string.max": joiMessage.add_graduate_modal.universityNameAR.max,
        }),
        universityNameEN: Joi.string().custom((value, helpers) => {
            // Check if the string contains only English letters and spaces
            if (!/^[A-Za-z\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.english_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).min(2).max(100).optional()
            .messages({
                "string.empty": joiMessage.common.required,
                "string.base": joiMessage.add_graduate_modal.universityNameEN.base,
                "string.min": joiMessage.add_graduate_modal.universityNameEN.min,
                "string.max": joiMessage.add_graduate_modal.universityNameEN.max,
            }),
        studyType: Joi.string().optional().valid("صباحي", "مسائي").messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.studyType.base,
            "any.only": joiMessage.add_graduate_modal.studyType.valid
        }),
        collegeName: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(100).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.collegeName.base,
            "string.min": joiMessage.add_graduate_modal.collegeName.min,
            "string.max": joiMessage.add_graduate_modal.collegeName.max,
        }),
        department: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(100).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.add_graduate_modal.department.base,
            "string.min": joiMessage.add_graduate_modal.department.min,
            "string.max": joiMessage.add_graduate_modal.department.max,
        }),
        rate: Joi.number().positive().precision(3).optional().messages({
            "string.empty": joiMessage.common.required,
            "number.base": joiMessage.add_graduate_modal.rate.number,
            "number.precision": joiMessage.add_graduate_modal.rate.precision,
            "number.positive": joiMessage.add_graduate_modal.rate.number
        }),
        rank: Joi.string().valid("1st", "2nd", "3rd").optional().messages({
            "string.empty": joiMessage.common.required,
            "any.only": joiMessage.add_graduate_modal.rank.valid
        }),
        graduate_year: Joi.string()
            .pattern(/^[0-9]{4}$/)
            .optional()
            .messages({
                "string.empty": joiMessage.common.required,
                "string.pattern.base": joiMessage.add_graduate_modal.graduate_year.pattern,
            })
    };

    return schema;
}

