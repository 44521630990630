import React, { useState } from "react";


// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";


// shared component
import MainFooter from './shared/MainFooter';
import MainHeader from './shared/MainHeader';

const ShowGraduateModal = ({
    show,
    onHide,
    dataTranslate,
    data,
}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            backdrop="static"
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="forget-pass-otp-form"
            dir={"rtl"}
        >
            <>
                <MainHeader dataTranslate={dataTranslate} data={data} functions={{
                    onHide
                }} />
                <Modal.Body>
                    <div dir={"rtl"}>
                        <Card>
                            <Card.Header>
                                {dataTranslate.popups.home_p.show_modal.user_title}
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-lg-3">
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.home_p.show_modal.userName
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="userName"
                                                disabled={true}
                                                value={data.userName}
                                                dir="ltr"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.home_p.show_modal.password
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="password"
                                                disabled={true}
                                                value={data.password}
                                                dir="ltr"
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Header>
                                {dataTranslate.popups.add_graduate.personal_information_p}
                            </Card.Header>
                            <Card.Body>
                                <Container>

                                    <Row className="mb-lg-3">
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.firstName
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="Text"
                                                    name="firstName"
                                                    disabled={true}
                                                    defaultValue={data.firstName}
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.secondName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="secondName"
                                                    value={data.secondName}
                                                    disabled={true}
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.thirdName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="thirdName"
                                                    placeholder={
                                                        dataTranslate.arabic_language
                                                    }
                                                    disabled={true}
                                                    value={data.thirdName}
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.fourthName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="fourthName"
                                                    disabled={true}
                                                    value={data.fourthName}
                                                />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-lg-3">
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.sureName
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="sureName"
                                                    disabled={true}
                                                    value={data.sureName}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.motherFirstName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="Text"
                                                    name="motherFirstName"
                                                    disabled={true}
                                                    value={data.motherFirstName}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.motherSecondName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="motherSecondName"
                                                    disabled={true}
                                                    value={data.motherSecondName}
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col lg={true} className="mb-3 mb-lg-0">
                                            <Form.Group>
                                                <Form.Label>
                                                    {
                                                        dataTranslate.popups.add_graduate.motherThirdName
                                                    }

                                                </Form.Label>
                                                <Form.Control
                                                    // disabled={!menuLoading ? true : false}
                                                    type="text"
                                                    name="motherThirdName"
                                                    disabled={true}
                                                    value={data.motherThirdName}
                                                />


                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Container>

                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Header>
                                {dataTranslate.popups.add_graduate.study_infromation_p}
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-lg-3">
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.scholarship
                                                }
                                            </Form.Label>
                                            <Form.Select
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="scholarship"
                                                disabled={true}
                                                value={data.scholarship}
                                            >
                                                <option hidden>
                                                    {data.scholarship == "false" ? "كلا" : "نعم"}
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.country
                                                }
                                            </Form.Label>
                                            <Form.Select
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="country"
                                                value={data.countryName}
                                                disabled
                                            >
                                                <option hidden>
                                                    {data.countryName}
                                                </option>

                                            </Form.Select>

                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.degree
                                                }
                                            </Form.Label>
                                            <Form.Select
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="id"
                                                defaultValue={data.id}
                                                disabled={true}
                                            >
                                                <option hidden>
                                                    {(() => {
                                                        switch (data.id) {
                                                            case 1:
                                                                return dataTranslate.popups.add_graduate.degree_type.pgd;
                                                            case 2:
                                                                return dataTranslate.popups.add_graduate.degree_type.bsc;
                                                            case 3:
                                                                return dataTranslate.popups.add_graduate.degree_type.msc;
                                                            case 4:
                                                                return dataTranslate.popups.add_graduate.degree_type.phd;
                                                            default:
                                                                return;
                                                        }
                                                    })()}
                                                </option>
                                            </Form.Select>

                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.universityNameAR
                                                }

                                            </Form.Label>
                                            <Form.Control
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="universityNameAR"
                                                disabled={true}
                                                value={data.universityNameAR}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-lg-3">
                                    {(() => {
                                        switch (data.scholarship) {
                                            case "true":
                                                return (
                                                    <Col lg={true} className="mb-3 mb-lg-0">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {
                                                                    dataTranslate.popups.add_graduate.universityNameEN
                                                                }
                                                            </Form.Label>
                                                            <Form.Control
                                                                // disabled={!menuLoading ? true : false}
                                                                type="text"
                                                                name="universityNameEN"
                                                                disabled={true}
                                                                value={data.universityNameEN}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )
                                            default:
                                                return <></>
                                        }
                                    })()}
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.collegeName
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="collegeName"
                                                disabled={true}
                                                value={data.collegeName}
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.department
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="department"
                                                disabled={true}
                                                value={data.departmentName}
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row className="mb-lg-3">
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.studyType
                                                }
                                            </Form.Label>
                                            <Form.Select
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="studyType"
                                                value={data.studyType}
                                                disabled={true}
                                            >
                                                <option hidden key="1">
                                                    {data.studyType}
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.rate
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                // disabled={!menuLoading ? true : false}
                                                type="number"
                                                name="rate"
                                                disabled={true}
                                                value={data.graduationRate}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.rank
                                                }
                                            </Form.Label>
                                            <Form.Select
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="rank"
                                                value={data.graduationRank}
                                                disabled={true}
                                            >
                                                <option value="1" key="2">
                                                    {"الاول"}
                                                </option>
                                                <option value="2" key="3">
                                                    {"الثاني"}
                                                </option>
                                                <option value="3" key="4">
                                                    {"الثالث"}
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={true} className="mb-3 mb-lg-0">
                                        <Form.Group>
                                            <Form.Label>
                                                {
                                                    dataTranslate.popups.add_graduate.graduate_year
                                                }
                                            </Form.Label>
                                            <Form.Select
                                                // disabled={!menuLoading ? true : false}
                                                type="text"
                                                name="graduate_year"
                                                value={data.graduate_year}
                                                disabled={true}
                                            >
                                                <option hidden>
                                                    {data.graduate_year}
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>
                </Modal.Body>

                <MainFooter functions={{
                    onHide
                }} dataTranslate={dataTranslate} />
            </>
        </Modal>
    )
}

export default ShowGraduateModal;
