import { verifyPassApi } from "./verifyPassApi";

export const verifyPassSetData = async (
  credintial,
  setMessage,
  setMessageResponse,
  setBtnLoader
) => {
  const response = await verifyPassApi(credintial);
  // set user data
  if (response.success) {
    setMessage((prev) => ({
      ...prev,
      success: true,
      message: response.res.data.message,
    }));
    setBtnLoader(() => false);
  } else {
    const error = response.res;
    if (error.response) {
      setMessageResponse((prev) => ({
        ...prev,
        success: false,
        message: error.response.data.message,
        isShow: true,
      }));
      setBtnLoader(() => false);
    } else {
      setMessageResponse((prev) => ({
        ...prev,
        success: false,
        message: "Timeout",
        isShow: true,
      }));
      setBtnLoader(() => false);
    }
  }
};
