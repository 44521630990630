import { isActiveUserApi } from "./isActiveUserApi";

export const isActiveUserSetData = async (
    setMessageResponse,
    cookies,
    id,
    setSuccessMessage,
    isActive
) => {
    const response = await isActiveUserApi(id, cookies, isActive);
    if (response.success) {
        setSuccessMessage((prev) => ({
            ...prev,
            success: true,
            data: response.res.data,
        }));
    } else {
        const error = response.res;
        if (error.response) {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: error.response.data.message,
                isShow: true,
            }));
        } else {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: "Timeout",
                isShow: true,
            }));
        }
    }
};
