// Bootstrap
import Modal from "react-bootstrap/Modal";

const MainHeader = ({ dataTranslate, functions }) => {
    return (
        <Modal.Header className="d-flex align-items-center justify-content-center ">
            <Modal.Title
                id="contained-modal-title-vcenter"
                className="fs-2 d-flex flex-row gap-2"
            >
                <span>
                    {dataTranslate.popups.search_graduate.title}
                </span>
            </Modal.Title>

            <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                functions.onHide();
            }}></button>
        </Modal.Header>
    )
}

export default MainHeader;