// Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
const MainFooter = ({ functions, status, dataTranslate }) => {
    return (
        <Modal.Footer
            className="w-100 d-flex flex-row align-items-center"
            dir="ltr"
            style={{ zIndex: '1001' }}
        >
            <div className="w-100 search_modal_btns d-flex flex-row  gap-2 " >


                <Button
                    disabled={status.btnLoader || status.activeSearchBtn === false}
                    onClick={() => {
                        functions.searchGraudate();
                    }}
                >
                    {dataTranslate.popups.search_graduate.search}
                </Button>
                <Button
                    onClick={() => {
                        functions.onHide();
                    }}
                >
                    {dataTranslate.popups.search_graduate.close}
                </Button>
                <NavLink style={{ marginLeft: "auto" }} to="#" onClick={(e) => {
                    e.preventDefault();
                    functions.setSearchData({})
                    functions.onHide();
                    functions.setActiveSearchBtn(false);
                    functions.setActiveSearchInput(false);
                    functions.setRefreshData((prev) => prev + 1)
                }}>{dataTranslate.popups.search_graduate.wipe}</NavLink>
            </div>
        </Modal.Footer>
    )
}

export default MainFooter;