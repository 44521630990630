import axios from "axios";

export const getGraduateCsvApi = async (
    cookies,
    setMessageResponse,
    setBtnLoader
) => {
    const token = cookies._token;
    let config = {
        method: "GET",
        responseType: "arraybuffer",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_API}/export-user-study-info`,
        headers: {
            Accept: "text/csv",
            Authorization: `Bearer ${token}`,
        },
        timeout: 60000,
    };

    axios(config)
        .then(async (response) => {
            let fileName = response.headers["content-type"];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName.split(/filename="(.+?)\.csv"/)[1]+".csv");
            document.body.appendChild(link);
            link.click();
            setBtnLoader(() => false);
        })
        .catch((error) => {
            console.log(error)
            setBtnLoader(() => false);

            if (error.response) {
                setMessageResponse((prev) => ({
                    ...prev,
                    success: false,
                    message: "حدث خطأ ما يرجى المحاولة لاحقاً",
                    isShow: true,
                }));
                setBtnLoader(() => false);
            } else {
                setMessageResponse((prev) => ({
                    ...prev,
                    success: false,
                    message: "تم فقدان الاتصال يرجى الحاولة مرة اخرة لاحقاً",
                    isShow: true,
                }));
                setBtnLoader(() => false);
            }
        });
};
