import { resetPassApi } from "./resetPassApi";

export const resetPassSetData = async (
  cookies,
  setCookie,
  credintial,
  setMessageResponse,
  setBtnLoader,
  onHide
) => {
  const response = await resetPassApi(credintial, cookies);
  if (response.success) {
    setMessageResponse((prev) => ({
      ...prev,
      success: true,
      message: response.res.data.message,
      isShow: true,
    }));
    setBtnLoader(() => false);
    // update token
    setCookie("_token", response.res.data.data.token);
    window.location.href = "/";
    onHide();
  } else {
    const error = response.res;
    if (error.response) {
      setMessageResponse((prev) => ({
        ...prev,
        success: false,
        message: error.response.data.message,
        isShow: true,
      }));
      setBtnLoader(() => false);
    } else {
      setMessageResponse((prev) => ({
        ...prev,
        success: false,
        message: "Timeout",
        isShow: true,
      }));
      setBtnLoader(() => false);
    }
  }
};
