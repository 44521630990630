import { getTableDataApi } from "./getTableDataApi";

export const getTableDataSet = async (
    setMessageResponse,
    cookies,
    action,
    setTableData,
    setTableLoader
) => {
    const response = await getTableDataApi(action, cookies);
    if (response.success) {
        setTableData(() => response.res.data.data);
        setTableLoader(() => false);
    } else {
        const error = response.res;
        if (error.response) {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: error.response.data.message,
                isShow: true,
            }));
        } else {
            setMessageResponse((prev) => ({
                ...prev,
                success: false,
                message: "Timeout",
                isShow: true,
            }));
        }
    }
}