import React, { useState, useEffect } from "react";

import Message from "../../Message";
import SuccessRes from "./SuccessRes";
import {
    editGraduatePeronsalDetailsSchema,
    editGraduateStudyDetailsSchema,
    editGraduateStudyDetailsSchemaPlus
} from "../../../../joi/editGraduateSchema";
// import { editShipmentSetData } from "../../../../../context/api/shipment/edit-shipment/editShipmentSetData";
import { useCookies } from "react-cookie";
import Joi from 'joi';
import { editGraduateSetData } from "../../../../../context/api/graduate/edit-graduate/editGraduateSetData";
// Bootstrap
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";



import MainFooter from "../shared/MainFooter";
import MainHeader from "../shared/MainHeader";

const EditGraduateModal = ({
    show,
    onHide,
    action,
    data,
    setRefreshData,
    dataTranslate,
    countries,
    iraqiUniversities,
    getLastFiveYears
}) => {
    const [tempData, setTempData] = useState({});

    useEffect(() => {
        setTempData(data);
    }, [data])

    const personalInfoSchmea = editGraduatePeronsalDetailsSchema();
    const studyInfoSchmea = editGraduateStudyDetailsSchema();
    const studyInfoSchmeaPlus = editGraduateStudyDetailsSchemaPlus();

    const initialMessageRes = {
        success: Boolean,
        message: String,
        isShow: false,
    };

    const initialSuccessMessage = {
        success: false,
        type: "",
        data: {},
    };

    const initStudyInfo = {
        scholarship: null,
        country: "",
        id: "",
        universityNameAR: "",
        universityNameEN: "",
        studyType: "",
        collegeName: "",
        departmentName: "",
        graduationRate: "",
        graduationRank: "",
        graduate_year: ""
    };

    const [personalInfo, setPeronsalInfo] = useState({

    });
    const [studyInfo, setStudyInfo] = useState({

    });

    const [errors, setErrors] = useState({});
    const [messageResponse, setMessageResponse] = useState(initialMessageRes);
    const [successMessage, setSuccessMessage] = useState(initialSuccessMessage);
    const [cookies] = useCookies();
    const [btnLoader, setBtnLoader] = useState(false);

    const [EXRLoading, setEXRLoading] = useState(false);
    const [EXRData, setEXRData] = useState([]);
    const [changedScholarShip, setChangedScholarShip] = useState(false);
    const [activeEditBtn, setActiveEditBtn] = useState(false);

    const editGraduate = () => {
        var objectSchema
        console.log(changedScholarShip);
        if (changedScholarShip === true) {
            objectSchema = Joi.object().keys({ ...personalInfoSchmea, ...studyInfoSchmeaPlus });
        } else {
            objectSchema = Joi.object().keys({ ...personalInfoSchmea, ...studyInfoSchmea });
        }
        console.log(objectSchema)
        const graduateData = { ...personalInfo, ...studyInfo };
        const result = objectSchema.validate(graduateData, { abortEarly: false });
        const { error } = result;
        if (!error) {
            // pass the payload to the context API to do the request
            setBtnLoader(() => true);
            return editGraduateSetData(
                setMessageResponse,
                cookies,
                graduateData,
                setSuccessMessage,
                setBtnLoader,
                data._id,
                data
            );
        } else {
            const errorData = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                errorData[name] = message;
            }
            console.log(errorData)
            setErrors(errorData);
            return errorData;
        }
    }

    const personalInfoOnChange = (e) => {
        if (activeEditBtn === false) {
            setActiveEditBtn(true);
        }
        const { name, value } = e.target;
        let personalInfoErrorsData = { ...errors };
        const errorMessage = validateProperty(e, personalInfoSchmea);
        if (errorMessage) {
            personalInfoErrorsData[name] = errorMessage;
        } else {
            delete personalInfoErrorsData[name];
        }
        let personalInfoData = { ...personalInfo };
        personalInfoData[name] = value;
        setPeronsalInfo(personalInfoData)
        setErrors(personalInfoErrorsData)
    }

    const studyInfoOnChange = (e) => {
        if (activeEditBtn === false) {
            setActiveEditBtn(true);
        }
        const { name, value } = e.target;
        let studyInfoErrorsData = { ...errors };
        var errorMessage
        // check if the employee has change the scholarship value 
        if (changedScholarShip === true) {
            errorMessage = validateProperty(e, studyInfoSchmeaPlus);
        } else if (changedScholarShip === false) {
            errorMessage = validateProperty(e, studyInfoSchmea);
        }
        if (errorMessage) {
            studyInfoErrorsData[name] = errorMessage;
        } else {
            delete studyInfoErrorsData[name];
        }
        let studyInfoData = { ...studyInfo };
        studyInfoData[name] = value;
        setStudyInfo(studyInfoData);
        setTempData({ ...tempData, ...studyInfoData })
        setErrors(studyInfoErrorsData);
    }

    const handleOnChange = (e) => { }

    const validateProperty = (event, schema) => {
        const { name, value } = event.target;
        const obj = { [name]: value };
        const subSchema = schema[name];
        const result = subSchema.validate(value);
        const { error } = result;
        return error ? error.details[0].message : null;
    }
    const reset = () => {
        setPeronsalInfo({
        })
        setStudyInfo({

        })
        setTempData({

        })
        setChangedScholarShip(false)
        setErrors({
        })
    }

    useEffect(() => {
        if (tempData.scholarship === "true") {
            setTempData({ ...data, ...initStudyInfo, scholarship: "false", country: "Iraq" })
            setStudyInfo({ ...initStudyInfo, scholarship: "false", country: "Iraq" })
            if (activeEditBtn === false) {
                setActiveEditBtn(true);
            }
        } else if (tempData.scholarship === "false") {
            setTempData({ ...data, ...initStudyInfo, scholarship: "true" })
            setStudyInfo({ ...initStudyInfo, scholarship: "true" })
            if (activeEditBtn === false) {
                setActiveEditBtn(true);
            }
        }
    }, [changedScholarShip])

    return (
        <>
            <Message
                messageResponse={messageResponse}
                close={() => setMessageResponse(initialMessageRes)}
            />

            <Modal
                show={show}
                onHide={onHide}
                backdrop="static"
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="forget-pass-otp-form"
                dir={"rtl"}
            >
                {successMessage.success ? (
                    <SuccessRes
                        dataTranslate={dataTranslate}
                        onHide={async () => {
                            await onHide();
                            setSuccessMessage(initialSuccessMessage);
                            setRefreshData((prev) => prev + 1);
                        }}
                        res={successMessage}
                    />
                ) : (
                    <>
                        <MainHeader dataTranslate={dataTranslate} data={data} action={action} functions={{
                            reset, onHide, setEXRLoading,
                            setEXRData, setActiveEditBtn
                        }} />
                        <Modal.Body>
                            <div dir={"rtl"}>
                                <Card>
                                    <Card.Header>
                                        {dataTranslate.personal_information_p}
                                    </Card.Header>
                                    <Card.Body>
                                        <Container>

                                            <Row className="mb-lg-3">
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.firstName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="Text"
                                                            name="firstName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.firstName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.firstName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.secondName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="secondName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.secondName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.secondName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.thirdName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="thirdName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.thirdName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.thirdName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.fourthName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="fourthName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.fourthName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.fourthName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className="mb-lg-3">
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.sureName
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="sureName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.sureName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.sureName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.motherFirstName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="Text"
                                                            name="motherFirstName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.motherFirstName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.motherFirstName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.motherSecondName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="motherSecondName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.motherSecondName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.motherSecondName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={true} className="mb-3 mb-lg-0">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            {
                                                                dataTranslate.motherThirdName
                                                            }
                                                            {action === "add" ? (
                                                                <span>
                                                                    <sup style={{ color: "red" }}> *</sup>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            // disabled={!menuLoading ? true : false}
                                                            type="text"
                                                            name="motherThirdName"
                                                            placeholder={
                                                                dataTranslate.arabic_language
                                                            }
                                                            onChange={personalInfoOnChange}
                                                            defaultValue={tempData.motherThirdName}
                                                        />

                                                        <Form.Text style={{ color: "red" }}>
                                                            {errors.motherThirdName}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        </Container>

                                    </Card.Body>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        {dataTranslate.study_infromation_p}
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="mb-lg-3">
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.scholarship
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="scholarship"
                                                        defaultValue={data.scholarship}
                                                        onChange={(e) => {
                                                            setChangedScholarShip(Boolean(e.target.value));
                                                        }}
                                                    >
                                                        <option hidden>
                                                            {dataTranslate.scholarship_type.choose}
                                                        </option>
                                                        <option value="true">
                                                            {dataTranslate.scholarship_type.true}
                                                        </option>
                                                        <option value="false">
                                                            {dataTranslate.scholarship_type.false}
                                                        </option>
                                                    </Form.Select>

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.scholarship}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.country
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    {(() => {
                                                        switch (tempData.scholarship) {
                                                            case "true":
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="country"
                                                                        defaultValue={tempData.country}
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.country_type.choose}
                                                                        </option>
                                                                        {countries.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.arabicName}>
                                                                                    {item.arabicName}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )
                                                            case "false":
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="country"
                                                                        defaultValue={tempData.country}
                                                                        disabled
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option selected disabled>
                                                                            {"العراق"}
                                                                        </option>

                                                                    </Form.Select>
                                                                )
                                                            default:
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="country"
                                                                        defaultValue={tempData.country}
                                                                        disabled
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.country_type.choose}
                                                                        </option>

                                                                    </Form.Select>
                                                                )
                                                        }
                                                    })()}
                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.country}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.degree
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="id"
                                                        value={tempData.id}
                                                        disabled={tempData.country === "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden value="" key="1">
                                                            {dataTranslate.studyType_type.choose}
                                                        </option>
                                                        <option value={1}>
                                                            {dataTranslate.degree_type.pgd}
                                                        </option>
                                                        <option value={2}>
                                                            {dataTranslate.degree_type.bsc}
                                                        </option>
                                                        <option value={3}>
                                                            {dataTranslate.degree_type.msc}
                                                        </option>
                                                        <option value={4}>
                                                            {dataTranslate.degree_type.phd}
                                                        </option>
                                                    </Form.Select>

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.id}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.universityNameAR
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    {(() => {
                                                        switch (tempData.scholarship) {
                                                            case "true":
                                                                return (
                                                                    <Form.Control
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameAR"
                                                                        placeholder={
                                                                            dataTranslate.universityNameAR_type.write
                                                                        }
                                                                        disabled={tempData.id === "" ? true : false}
                                                                        onChange={studyInfoOnChange}
                                                                        value={tempData.universityNameAR}
                                                                    />
                                                                )
                                                            case "false":
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameAR"
                                                                        value={tempData.universityNameAR}
                                                                        disabled={tempData.id === "" ? true : false}
                                                                        onChange={studyInfoOnChange}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.universityNameAR_type.choose}
                                                                        </option>
                                                                        {iraqiUniversities.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.universityNameAR}>
                                                                                    {item.universityNameAR}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )
                                                            default:
                                                                return (
                                                                    <Form.Select
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameAR"
                                                                        disabled={tempData.id === "" ? true : false}
                                                                        onChange={studyInfoOnChange}
                                                                        value={tempData.universityNameAR}
                                                                    >
                                                                        <option hidden>
                                                                            {dataTranslate.universityNameAR_type.choose}
                                                                        </option>
                                                                        {iraqiUniversities.map((item, index) => {
                                                                            return (
                                                                                <option key={index} value={item.universityNameAR}>
                                                                                    {item.universityNameAR}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )
                                                        }
                                                    })()}

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.universityNameAR}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-lg-3">
                                            {(() => {
                                                switch (tempData.scholarship) {
                                                    case "true":
                                                        return (
                                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                                <Form.Group>
                                                                    <Form.Label>
                                                                        {
                                                                            dataTranslate.universityNameEN
                                                                        }
                                                                        {action === "add" ? (
                                                                            <span>
                                                                                <sup style={{ color: "red" }}> *</sup>
                                                                            </span>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        // disabled={!menuLoading ? true : false}
                                                                        type="text"
                                                                        name="universityNameEN"
                                                                        disabled={tempData.id === "" ? true : false}
                                                                        placeholder={
                                                                            dataTranslate.universityNameEN_type.write
                                                                        }
                                                                        onChange={studyInfoOnChange}
                                                                        value={tempData.universityNameEN}
                                                                    />
                                                                    <Form.Text style={{ color: "red" }}>
                                                                        {errors.universityNameEN}
                                                                    </Form.Text>
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                    default:
                                                        return <></>
                                                }
                                            })()}
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.collegeName
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="Text"
                                                        name="collegeName"
                                                        placeholder={
                                                            dataTranslate.collageName_p
                                                        }
                                                        disabled={tempData.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                        value={tempData.collegeName}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.collegeName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.department
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="Text"
                                                        name="departmentName"
                                                        placeholder={
                                                            dataTranslate.department_p
                                                        }
                                                        disabled={tempData.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                        value={tempData.departmentName}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.departmentName}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row className="mb-lg-3">
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.studyType
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="studyType"
                                                        value={tempData.studyType}
                                                        disabled={tempData.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden value="" key="1">
                                                            {dataTranslate.studyType_type.choose}
                                                        </option>
                                                        <option value={dataTranslate.studyType_type.morning} key="2">
                                                            {dataTranslate.studyType_type.morning}
                                                        </option>
                                                        <option value={dataTranslate.studyType_type.evening} key="3">
                                                            {dataTranslate.studyType_type.evening}
                                                        </option>

                                                    </Form.Select>

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.studyType}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.rate
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={!menuLoading ? true : false}
                                                        type="number"
                                                        name="graduationRate"
                                                        placeholder={
                                                            dataTranslate.rate_p
                                                        }
                                                        min={0}
                                                        max={100}
                                                        disabled={tempData.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                        value={tempData.graduationRate}
                                                    />

                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.graduationRate}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.rank
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="graduationRank"
                                                        value={tempData.graduationRank}
                                                        disabled={tempData.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden value="" key="1">
                                                            {dataTranslate.studyType_type.choose}
                                                        </option>
                                                        <option value="1" key="2">
                                                            {"الاول"}
                                                        </option>
                                                        <option value="2" key="3">
                                                            {"الثاني"}
                                                        </option>
                                                        <option value="3" key="4">
                                                            {"الثالث"}
                                                        </option>
                                                    </Form.Select>
                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.graduationRank}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={true} className="mb-3 mb-lg-0">
                                                <Form.Group>
                                                    <Form.Label>
                                                        {
                                                            dataTranslate.graduate_year
                                                        }
                                                        {action === "add" ? (
                                                            <span>
                                                                <sup style={{ color: "red" }}> *</sup>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Form.Label>
                                                    <Form.Select
                                                        // disabled={!menuLoading ? true : false}
                                                        type="text"
                                                        name="graduate_year"
                                                        value={tempData.graduate_year}
                                                        disabled={tempData.id == "" ? true : false}
                                                        onChange={studyInfoOnChange}
                                                    >
                                                        <option hidden value="" key="1">
                                                            {dataTranslate.studyType_type.choose}
                                                        </option>
                                                        {getLastFiveYears.map((item, index) => {
                                                            return (
                                                                <option value={item} key={index}>
                                                                    {item}
                                                                </option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                    <Form.Text style={{ color: "red" }}>
                                                        {errors.graduate_year}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </div>
                        </Modal.Body>
                        <MainFooter functions={{
                            editGraduate, reset, onHide, setEXRLoading,
                            setEXRData, setActiveEditBtn
                        }} status={{ btnLoader, activeEditBtn }} dataTranslate={dataTranslate} action={action} />
                    </>
                )}
            </Modal>
        </>
    );
}


export default EditGraduateModal;