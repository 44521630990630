import Joi from "joi";

export const editUserSchema = () => {
    const joiMessage = require(`../../translate/arabic/joi.json`);
    const schema = {
        email: Joi.string().email({ tlds: { allow: false } }).optional().messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.user_modal.email.base,
            "string.email": joiMessage.user_modal.email.email
        }),
        name: Joi.string().custom((value, helpers) => {
            // Check if the string contains only Arabic letters and spaces
            if (!/^[ء-ي\s]+$/.test(value)) {
                return helpers.message(joiMessage.common.arabic_only);
            }
            // Check if the string ends with a space
            if (/\s$/.test(value)) {
                return helpers.message(joiMessage.common.no_space_arabic);
            }
            return value; // Return the valid value
        }).optional().min(2).max(40).messages({
            "string.empty": joiMessage.common.required,
            "string.base": joiMessage.user_modal.affairs.base,
            "string.min": joiMessage.user_modal.affairs.min,
            "string.max": joiMessage.user_modal.affairs.max,
        }),
        role_name: Joi.string().valid("super-admin", "admin", "staff", "user").optional().messages({
            "string.empty": joiMessage.common.required,
            "any.only": joiMessage.user_modal.role.valid
        }),
        password: Joi.string()
                .optional()
                .regex(/^(?=.*\d)(?=.*[a-z]).{8,}$/)
                .min(8)
                .max(40)
                .messages({
                    "string.empty": joiMessage.common.required,
                    "string.base": joiMessage.login.password.case1,
                    "string.pattern.base": joiMessage.login.password.case2,
                    "string.min": joiMessage.login.password.min,
                    "string.max": joiMessage.login.password.max,
                }),
    };

    return schema;
}

