import axios from "axios";

export const verifyPassApi = async (credintial) => {
  let data = JSON.stringify({
    token: credintial.token,
    otp: credintial.otp,
    newPassword: credintial.newPassword,
  });

  let config = {
    method: "post",
    url: `${process.env.REACT_APP_BASE_API}/verify-forget-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 15000,
  };

  try {
    const res = await axios(config);
    return { success: res.data.success, res: res };
  } catch (error) {
    return { success: false, res: error };
  }
};
