// Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const MainFooter = ({ functions, status, dataTranslate }) => {
    return (
        <Modal.Footer
            className="w-100 d-flex flex-row align-items-center"
            dir="ltr"
            style={{ zIndex: '1001' }}
        >
            <div className="w-100 show_modal_btns d-flex flex-row align-items-center justify-content-center gap-2 " >

                <Button
                    onClick={() => {
                        functions.onHide();
                    }}
                >
                    {dataTranslate.popups.home_p.close}
                </Button>
            </div>
        </Modal.Footer>
    )
}

export default MainFooter;