import axios from "axios";
import { signout } from "../../../components/utils/extra/signout";

export const verifyApi = async (setAuth, token, removeCookies) => {
    let config = {
        method: "POST",
        url: `${process.env.REACT_APP_BASE_API}/auth/verify`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        timeout: 15000,
    };

    try {
        const res = await axios(config);

        setAuth((prev) => ({
            ...prev,
            isAuthenticated: true
        }));
    } catch (error) {
        if (error.response) {
            signout(setAuth, removeCookies);
        }
    }
};
