import React, { useState } from "react";
import ForgetPassModal from "../../utils/modals/ForgetPassModal";
import Logo from "../../../assets/img/Logo.png";
import { useValidator } from "react-joi";
// ======= Bootstrp Components=======
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// components
import { joiErrorMessage } from "../../utils/extra/joiErrorMessage";
import { forgetPassSetData } from "../../../context/api/forget-pass/forgetPassSetData";
import Message from "../../utils/modals/Message";
import { forgetPassSchema } from "../../joi/forgetPassSchema";
import { NavLink } from "react-router-dom";
const ForgetPass = () => {
  const data = require(`../../../translate/arabic/data.json`);

  const initialVerifyState = {
    token: String,
    otp: String,
    email: String,
    isShow: false,
  };
  const initialMessageResState = {
    success: Boolean,
    message: String,
    isShow: false,
  };

  const [verifyModal, setVerifyModal] = useState(initialVerifyState);
  const [btnLoader, setBtnLoader] = useState(false);
  const [messageResponse, setMessageResponse] = useState(
    initialMessageResState
  );
  const { state, setData, setExplicitField, validate } = useValidator(
    forgetPassSchema().schema
  );

  const updateEmail = (e) => {
    // react < v17
    e.persist();

    setData((old) => ({
      ...old,
      email: e.target.value,
    }));
  };

  const postData = (e) => {
    e.preventDefault();
    const credintial = {
      email: state.$data.email,
    };
    if (state.$all_source_errors.length < 1) {
      setBtnLoader(() => true);
      return forgetPassSetData(
        credintial,
        setMessageResponse,
        setBtnLoader,
        setVerifyModal
      );
    }
  };

  return (
    <>
      <Message
        messageResponse={messageResponse}
        close={() => setMessageResponse(initialMessageResState)}
      />

      <div className="forget_pass_p d-flex align-items-center justify-content-center">
        <ForgetPassModal
          show={verifyModal}
          onHide={() => setVerifyModal(initialVerifyState)}
          setMessageResponse={setMessageResponse}
          token={verifyModal.token}
        />
        <div className="forget_pass_p-form d-flex flex-column align-items-center">
          <img src={Logo} width={105} alt="مجلس الخدمة العامة الاتحادي" />
          <Form
            className="w-100 px-4 pb-4"
            dir={"rtl"}
            onSubmit={(e) => {
              validate();
              postData(e);
            }}
          >
            <div>
              <h4>{data.pages.forget_pass_p.title}</h4>
              <p>{data.pages.forget_pass_p.desc}</p>
            </div>
            <Form.Group className="my-4" controlId="formBasicEmail">
              <Form.Label>{data.pages.forget_pass_p.email}</Form.Label>
              <Form.Control
                className="forget_pass_p-form-inputs"
                type="text"
                placeholder={data.pages.forget_pass_p.input1_placeholder}
                onChange={updateEmail}
                onBlur={() => setExplicitField("email", true)}
              />
              <Form.Text style={{ color: "red" }}>
                {joiErrorMessage(state.$errors.email)}
              </Form.Text>
            </Form.Group>

            <div className="forget_pass_p-form-btns d-flex align-items-center gap-3">
              <Button
                className=" w-100 btn btn-primary"
                variant="primary"
                type="submit"
                disabled={btnLoader ? true : false}
              >
                {data.pages.forget_pass_p.submit}
              </Button>
              <NavLink
                to="/login"
                className=" w-100 btn btn-primary"
              >
                {data.pages.forget_pass_p.back}
              </NavLink>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default ForgetPass;
