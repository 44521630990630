// home page
export const homeSwitcher_1 = (item, dataTranslate) => {
    const data = dataTranslate.pages.home_p.cards;
    switch (Object.keys(item)[0]) {
        case "all":
            return { key: "all", title: data.card1 };

        case "bsc":
            return { key: "bsc", title: data.card2 };

        case "pgd":
            return { key: "pgd", title: data.card3 };

        case "msc":
            return { key: "msc", title: data.card4 };

        case "phd":
            return { key: "phd", title: data.card5 };

        default:
    }
};

export const officeSwitcher_1 = (item, dataTranslate) => {
    const data = dataTranslate.pages.office_p.cards;
    switch (Object.keys(item)[0]) {
        case "active":
            return { key: "active", title: data.card1 };

        case "blocked":
            return { key: "blocked", title: data.card2 };

        default:
    }
};