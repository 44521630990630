import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Message = ({ messageResponse, close}) => {
  const dataTranslate = require(`../../../translate/arabic/data.json`);

  let data = {
    header: "",
    bodyColor: "#2b506d",
    closeBtn: dataTranslate.addotional.close,
  };

  switch (messageResponse.success) {
    case true:
      data.header = dataTranslate.addotional.success_message;
      break;
    case false:
      data.header = dataTranslate.addotional.failure_message;
      data.bodyColor = "#e65857";
      break;

    default:
  }
  return (
    <>
      <Modal show={messageResponse.isShow} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{data.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          dir={"rtl"}
          style={{ color: data.bodyColor }}
        >
          {messageResponse.message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            {data.closeBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Message;
